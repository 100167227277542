import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import { Container } from "reactstrap";
import TextWithTitle from "../common/TextWithTitle";
import LeftImageBox from "../common/LeftImageBox";
import aggreement from "../../images/aggrement.svg";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../constants";

class Agreements extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <Container
                style={{
                    padding: 0
                }}
                fluid={true}
            >
                <Banner />
                <Header />
                <Helmet><title>{APP_NAME} | Compliance</title></Helmet>
                <TextWithTitle
                    title="Compliance"
                    text="Osrit Safety not only offers solutions to assist you in maintaining compliance but we deliver the news you need to know to stay in the know. Osrit is designed to improve your freight and
                        distribution channels by keeping the deliveries you
                        manage under your control. Osrit helps you keep your
                        dispatchers, equipment, and loads all on the same page.
                        Osrit helps manage and track those deliveries for
                        Carriers, Brokers, 3rd Party Logistics (3PLs), Suppliers
                        and Manufacturers. Osrit is loaded with features that
                        help you compete in today's logistics world by offering
                        a higher level of service than your competitors. Created
                        by people from the trucking industry that assure
                        reliability, Osrit is continually improving to meet the
                        needs of today's high demand Trucking and Brokerage
                        professionals. Osrit is designed for customers advanced
                        features of Tracking and Remote Immobilization that
                        keeps the track of various features of vehicles like
                        ignition, speed over limit etc." />
                <LeftImageBox
                    image={aggreement}
                    alt="Trucking Agreements Compliance"
                    title="Agreements"
                    list={["Driver Agreement", "Brokerage Agreement", "Shipper / Consignee Agreement"]}
                    desc="Agreements play a vital role in the trucking business. Agreements help you to avoid any potential dispute and also creates clarity in your business. Osrit Software provides you ready-made agreements that can help you in every aspect of your business. Enter the particulars and the agreement is ready with just one click of a button. Some of the agreement types are listed below:"
                />
            </Container>
        );
    }
}

export default Agreements;
