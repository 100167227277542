import React from 'react';
import Helmet from 'react-helmet';
import { APP_NAME, isLive } from '../../constants';
import Banner from '../Banner';
import Header from '../Header';
import Content from './Content';
import './terms.scss';
// import {Container, Row, Col} from 'reactstrap';

class TermsOfService extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (<div id="termsWrapper">
            <Helmet>
                <title>Terms of Service - {APP_NAME}</title>
                {isLive && <meta name="description" content="The Customer Terms of Service outline your rights and obligations when using the Osrit Cloud Software platform. Learn about our terms and conditions here!" />}
            </Helmet>

            <Banner />
            <Header />
            <Content />
        </div>)
    }
}

export default TermsOfService;
