import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import { Container } from "reactstrap";
import LeftImageBox from "../common/LeftImageBox";
import TextWithTitle from "../common/TextWithTitle";

class Tracking extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <Container
                style={{
                    padding: 0
                }}
                fluid={true}
            >
                <Banner />
                <Header />
                <TextWithTitle title="Tracking" />
                <LeftImageBox image="tracking2.jpg"
                    alt="GPS Tracking Device"
                    desc="The Osrit Software helps you to track real-time location of your drivers any time,
                        as well as you can check where they were yesterday and where they’ll be in the coming
                        days and months."
                    desc2="With the help of GPS tracking, you can make more informed decisions
                        about fleet operations. It becomes easier for you to do route optimisation and check
                        if there are any maintenance needs and keep track of asset availability and utilisation.You can easily track your drivers on the road, whether vehicles are idle or traveling to
                        and from a job."
                />

            </Container>
        );
    }
}

export default Tracking;
