import React from 'react';
import { Form, FormGroup, Input, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Button, Alert, } from 'reactstrap';
import { contactUs } from '../../actions/appActions';

class PriceForm extends React.Component {
    state = {
        error: false,
        success: false,
        loading: false,
        unvalid: false
    }
    handleClick = async () => {
        const { name, phone, email, company } = this.state
        const { products, vehicles } = this.props
        this.setState({
            success: false,
            loading: false,
            error: false,
            unvalid: false
        })


        if (!name) {
            this.setState({ unvalid: true })
            return
        }
        if (!phone) {
            this.setState({ unvalid: true })
            return
        }
        if (phone.length < 8) {
            this.setState({ unvalid: true })
            return
        }

        let message = ""
        if (company) {
            message = "Company: " + company + "; "
        }
        if (vehicles) {
            message = message + "Vehicles: " + vehicles + "; "
        }
        if (products) {
            message = message + "Products: "
            products.forEach((element) => {
                message = message + element + ", "
            });
        }
        console.log(message)

        if (!_isEmpty(this.props.allErrors)) {
            this.setState({ globalError: true });
            return;
        }

        const payload = {
            fullname: `${name}`,
            email: email,
            phonenumber: phone,
            subject: 'Price query from www.osrit.com',
            message: message,
            dateadded: new Date()
        }
        this.setState({
            loading: true,
        })
        const response = await this.props.contactUs(payload);
        const isSuccess = _get(response, 'message.body.status') === 'success';
        this.setState({
            success: isSuccess,
            loading: false,
            error: !isSuccess
        })
    }

    onClose = () => {
        this.setState({ globalError: false, isReset: false })
    }

    alerts = () => {
        const allErrors = this.props.allErrors;
        return (<Alert color="">
            {
                allErrors && Object.keys(allErrors).map((item, i) => (<div key={i} style={{
                    fontSize: 14,
                    marginTop: 5
                }}>
                    <i className="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>{' '}
                    {allErrors[item]}</div>))
            }
        </Alert>)
    }


    render() {
        const { error, success, loading, unvalid } = this.state
        return (
            <div> <h2 className="h2tag">Contact Information</h2>
                <div className="form">
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col data-aos="fade-right">
                                    <Input id="name" placeholder="Full Name*"
                                        onChange={(e) => { this.setState({ name: e.target.value }) }}
                                    /></Col>
                            </Row>
                            <br />
                            <Row>
                                <Col data-aos="fade-right" >
                                    <Input id="Phone" type="number" placeholder="Phone*"
                                        onChange={(e) => { this.setState({ phone: e.target.value }) }}
                                    /></Col ></Row>
                            <br />
                            <Row>
                                <Col data-aos="fade-right">
                                    <Input id="Email" type="email" placeholder="Email"
                                        onChange={(e) => { this.setState({ email: e.target.value }) }}
                                    />
                                </Col ></Row>
                            <br />
                            <Row>
                                <Col data-aos="fade-right">
                                    <Input id="CompanyName" placeholder="Company Name"
                                        onChange={(e) => { this.setState({ company: e.target.value }) }}
                                    />
                                </Col >
                            </Row>
                            {(error || success || unvalid) && <br />}
                            <Row>
                                <Col >
                                    {unvalid && <Alert color="danger" style={{ textAlign: "center" }}>Fields with * are required.. </Alert>}
                                    {error && <Alert color="danger" style={{ textAlign: "center" }}>Somthing went wrong.. Try again..</Alert>}
                                    {success && <Alert color="success" style={{ textAlign: "center" }}>
                                        Thanks for your interest.<br />
                                        We will contact you soon.<br />
                                        For immediate needs call +1 888 740 0937
                                    </Alert>}
                                </Col>
                            </Row>
                        </FormGroup>

                    </Form>
                </div >
                <div className="button_placement">
                    <Button size="sm" color="primary"
                        style={{ marginRight: 40 }}
                        onClick={() => { this.props.setStepAndData(2, this.props.products, this.props.vehicles) }}
                    >
                        BACK
                    </Button>
                    <Button size="sm" color="primary"
                        onClick={this.handleClick}
                        disabled={loading}
                    >
                        {loading ? "Submitting" : "SUBMIT"}
                    </Button>
                </div>


            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        contact: _get(state, 'form.contactForm.values', {}),
        allErrors: _get(state, 'form.contactForm.syncErrors', {}),
        allFields: _get(state, 'form.contactForm.fields', {}),
        appBusy: _get(state, 'appData.appBusy', false)
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({ contactUs }, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(PriceForm);

