import React from "react";
import { Row, Col, Button } from "reactstrap";

class vehicles extends React.Component {
    constructor(props) {
        super(props)
        console.log(props)
        this.state = {
            selected: props.vehicles || ""
        }
    }

    renderCard = (props) => {
        let { selected = "" } = this.state
        const isSelected = selected === props.title
        return (
            <div
                className={isSelected ? "selected_vehicle" : "vehicle_card  "}
                onClick={() => {
                    if (isSelected) {
                        selected = ""

                    } else {
                        selected = props.title
                    }
                    this.setState({ selected })
                    this.props.setStepAndData(3, this.props.products, selected)
                }}
            >

                {props.title}
            </div>
        )

    }
    render() {
        return (
            <div><h2 className="h2tag">Number of vehicles you operate</h2>
                <div className="cardplace_vehicle">
                    <Row>
                        <Col data-aos="fade-up-left" sm={12} md={6} lg="4" style={{ padding: 15, }} >
                            {this.renderCard(
                                { title: "1 - 5", id: "1" })}
                        </Col>
                        <Col data-aos="fade-up-left" sm={12} md={6} lg="4" style={{ padding: 15 }}>
                            {this.renderCard(
                                { title: "6 - 10", id: "2" })}
                            <Col />
                        </Col>
                        <Col data-aos="fade-up-left" sm={12} md={6} lg="4" style={{ padding: 15 }}>
                            {this.renderCard(
                                { title: "11 - 20", id: "3" })}
                        </Col></Row>
                    <Row>
                        <Col data-aos="fade-up-right" sm={12} md={6} lg={{ size: 4, offset: 2 }} style={{ padding: 15 }}>
                            {this.renderCard(
                                { title: "21 - 50", id: "4" })}
                        </Col>
                        <Col data-aos="fade-up-right" sm={12} md={6} lg="4" style={{ padding: 15 }}>
                            {this.renderCard(
                                { title: "51 +", id: "5" })}
                        </Col>
                    </Row>
                    <div data-aos="fade-up-left" className="button_placement">
                        <Button size="sm" color="primary"
                            style={{ marginTop: 10 }}
                            onClick={() => { this.props.setStepAndData(1, this.props.products, "") }}
                        >
                            BACK
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default vehicles;