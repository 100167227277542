import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import TextWithTitle from "../common/TextWithTitle";
import LeftImageBox from "../common/LeftImageBox";
import { Container } from "reactstrap";
import broker from "../../images/broker.svg";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../constants";

// import image from "../../images/12l.png";

class Brokerage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <Container
                style={{ padding: 0 }}
                fluid={true}
            >
                <Banner />
                <Header />
                <Helmet><title>{APP_NAME} | Brokerage Software</title></Helmet>
                <TextWithTitle
                    title="Brokerage"
                    text="The Osrit Software can help you to manage tedious paperwork and notifications. You can easily create invoices, manage accounts, and generate accounts receivable with one click. The Osrit software allows you to quickly send, edit, and create invoices.                        Managing payroll and manually sending invoices can be very difficult and you have to                        spend lot of time to manage everything. Osrit Software will help you to streamline all                        your tasks and helps you to increase your revenue. " />
                <LeftImageBox
                    image={broker}
                    title=""
                    alt="Broker"
                    desc="The Osrit software eliminates all the guesswork from payroll and invoicing. You will know exactly what you owe your employees and how much your customers owe you, and everything will be broken down. Your dispatches, expenses, and invoices will be completely accurate with minimal effort on your part."
                />
            </Container>
        );
    }
}

export default Brokerage;
