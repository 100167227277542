import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import innovationImage from "../../images/innovation.png";

const STYLES = {
    button: {
        color: "#fff",
        backgroundColor: "#20a8d8",
        borderColor: "#20a8d8",
        minWidth: 350,
        marginTop: 10
    },
    buttonLink: {
        color: "grey",
        marginTop: 10,
        fontSize: 12,
        textTransform: "uppercase",
        marginBottom: 25
    }
};
class AppsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        return (
            <Modal
                size="lg"
                isOpen={this.props.show}
                toggle={this.toggle}
                className={this.props.className}
            >
                <ModalBody>
                    <div className="text-center">
                        <img alt="DispatchBoard" src={innovationImage} />
                    </div>
                    <div className="text-center">
                        <h3>New osrit dashboard is now available</h3>
                        <h5>Blazing fast with many new features</h5>
                    </div>
                    <div className="text-center">
                        <Button
                            size="sm"
                            style={STYLES.button}
                            onClick={() => this.props.toggle("accept")}
                        >
                            {"TRY NEW DASHBOARD"}
                        </Button>
                    </div>
                    <div className="text-center">
                        <Button
                            size="sm"
                            color="link"
                            style={STYLES.buttonLink}
                            onClick={() => this.props.toggle("reject")}
                        >
                            {"Continue with old dashboard"}
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default AppsModal;
