const queryString = require('query-string');

module.exports.checkEmail = function(email) {
    if (!email) {
        return false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        return false;
    } else {
        return true;
    }
}

module.exports.getBrowser = function() {
    try {
        if (navigator.userAgent.search('MSIE') >= 0) {
            return 'InternetExplorer'
        } else if (navigator.userAgent.search('Chrome') >= 0) {
            return 'Chrome';
        } else if (navigator.userAgent.search('Firefox') >= 0) {
            return 'Firefox';
        } else if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
            return 'Safari';
        } else if (navigator.userAgent.search('Opera') >= 0) {
            return 'Opera';
        } else {
            return '';
        }
    } catch (e) {
        return '';
    }
}

module.exports.getLocation = function() {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                return resolve(position);
            }, (err) => {
                return reject(err)
            });
        }
    });
}

module.exports.getRedirectUrl = function() {
    const parsed = queryString.parse(window.location.search);
    const allKeys = Object.keys(parsed);
    let finalUrl = '';

    if (!parsed.redirectUrl) {
        return null;
    }

    allKeys.forEach((eKey) => {
        const eValue = parsed[eKey]
        if (eKey !== 'redirectUrl') {
            finalUrl = `${finalUrl}${eKey}=${eValue}&`
        }
    })
    return finalUrl
        ? `${parsed.redirectUrl}&${finalUrl}`
        : `${parsed.redirectUrl}`;
}
