const validate = (values = {}) => {

    const errors = {}

    if (!values.username) {
        errors.username = 'Username is required.'
    } else if (values.username.length < 4) {
        errors.username = 'Username too short.'
    }

    if (!values.password) {
        errors.password = 'Password is required.'
    } else if (values.password.length < 4) {
        errors.password = 'Password too short.'
    }

    // if (!values.token) {
    //     errors.token = 'Token is required.'
    // } else if (values.token.length < 6 || values.token.length > 6) {
    //     errors.token = 'Invalid token.'
    // }

    return errors
}

export default validate;
