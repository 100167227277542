import Billing from "../../images/accounting/billing.svg";
import reporting from "../../images/accounting/report.svg";
import dsettlements from "../../images/accounting/driversettlements.svg";
import oneclick from "../../images/accounting/oneclick.svg";
import create1099 from "../../images/accounting/create1099.svg";
import totalcosts from "../../images/accounting/trackcosts.svg";
import checkprint from "../../images/accounting/checkprinting.svg";
import psprinting from "../../images/accounting/paystubprinting.svg";


export const items = [
    {
        title: "Flexible, Accurate Billing",
        image: Billing,
        desc: "Osrit Software eliminates all the guesswork from payroll and invoicing. You will know exactly what you owe your employees and how much your customers owe you, and everything will be broken down. Your dispatches, expenses, and invoices will be completely accurate with minimal effort on your part.",
        alt: "Trucking Accounting Software"
    },
    {
        title: "Budget Reporting",
        image: reporting,
        desc: "Customizable software to meet the workflow needs of nearly any organization. Unlimited user seats, giving you the flexibility to use applications across all departments preventing siloes. Intuitive tools provided in one solution to run your entire business, delivering automation without complexity.",
        alt: "Trucking Reports"
    },
    {
        title: "Driver Settlements",
        image: dsettlements,
        desc: "Eliminate errors and cut the time it takes to generate and pay your staff, drivers. It is always demoralizing for everyone to argue with a driver about how many miles they drove and how much you owe them. Osrit Software will eliminate all the guesswork and will let you know exactly what you owe to your driver and how many miles each driver drove.",
        alt: "Driver Paycheck"
    },
    {
        title: "One click Invoice",
        image: oneclick,
        desc: "Osrit Software includes accounting modules that allows you to quickly send, edit, and create invoices with one click. You can create an invoice for single or multiple loads with just one click.",
        alt: "Trucking Invoice"
    },
    {
        title: "Create 1099",
        image: create1099,
        desc: "Osrit Software helps you to quickly compile all the information required to submit government forms like 1099 with the click of a button. ",
        alt: "Accounting Software"
    },
    {
        title: "Track total Costs",
        image: totalcosts,
        desc: "Osrit Software helps you to generate reports with one click of a button and you track all your expenses and earnings at one place. ",
        alt: "Accounting Software"
    },
    {
        title: "Check Printing",
        image: checkprint,
        desc: "Osrit Software allows you to generate all your reports with one click and then you can easily download and print each and every report for your record.",
        alt: "Driver Check Print"
    },
    {
        title: "Paystub Printing",
        image: psprinting,
        desc: "You can easily generate Pay stubs and pay checks for each driver by selecting one or multiple loads delivered by the driver. After generating pay checks and pay stubs you can simply download and then print it. ",
        alt: "Trucking Payment Software"
    }
];