import React from 'react';

class TextWithTitle extends React.Component {
    render() {
        const { title, text } = this.props
        return (<section style={{
            padding: 15,
            margin: '25px auto',
            maxWidth: 1000,
            width: '100%'
        }}>
            <h1 className="text-center h1">{title}</h1>
            {text && <div style={{
                fontSize: 18,
                justifyContent: "center", textAlign: 'justify',
                margin: '20px auto'
            }}>{text}</div>
            }
        </section>)
    }
}

export default TextWithTitle;
