import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import { Container } from "reactstrap";
import { items } from "./content";
import LeftImageBox from "../common/LeftImageBox";
import RightImageBox from "../common/RightImageBox";
import { Helmet } from "react-helmet";
import { APP_NAME, isLive } from "../../constants";


class Gps extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <Container
                style={{ padding: 0 }}
                fluid={true}
            >
                <Banner />
                <Header />
                <Helmet>
                    <title>Accounting Software for Trucking - {APP_NAME}</title>
                    {isLive && <meta name="description" content="Osrit is an accounting software designed specifically for truck drivers. It helps you manage your time, fuel costs, miles driven, and more. Contact us today!" />}
                </Helmet>
                <div>
                    <h4 className="text-uppercase text-center"
                        style={{ marginTop: 80, marginBottom: 60 }}
                    >
                        The accounting software with easy to use tools.
                    </h4>
                    <div
                        style={{
                            marginTop: 25,
                            textAlign: 'justify'
                        }}
                    >
                        {items.map((item, i) => (
                            i % 2 === 0 ?
                                <LeftImageBox
                                    key={i}
                                    image={item.image}
                                    alt={item.alt}
                                    title={item.title}
                                    desc={item.desc}
                                />
                                :
                                <RightImageBox
                                    key={i}
                                    alt={item.alt}
                                    image={item.image}
                                    title={item.title}
                                    desc={item.desc}
                                />))}
                    </div>
                </div>
            </Container>
        );
    }
}

export default Gps;
