import React from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {bindActionCreators} from 'redux';
import Header from '../Header';
import Footer from '../Footer';
import {Container, Col, Button, Alert} from 'reactstrap';
import ReduxInput from './../../components/ReduxInput';
import ReduxModal from './../../components/ReduxModal';
import {savePassword} from '../../actions/appActions';
import validate from './validate';
import './reset.scss';

class Reset extends React.Component {

    state = {
        error: '',
        success: '',
        globalError: false
    }

    componentDidMount() {
        const qp = queryString.parse(window.location.search);
        this.props.change('token', qp.access_token)
    }

    onClose = () => {
        this.setState({globalError: false, isReset: false})
    }

    alerts = () => {
        const allErrors = this.props.allErrors;
        return (<Alert color="">
            {
                allErrors && Object.keys(allErrors).map((item, i) => (<div key={i} style={{
                        fontSize: 14,
                        marginTop: 5
                    }}>
                    <i className="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>{' '}
                    {allErrors[item]}</div>))
            }
        </Alert>)
    }

    resetPassword = async () => {
        if (!_isEmpty(this.props.allErrors)) {
            this.setState({globalError: true});
            return;
        }
        const {token, password} = this.props.resetValues
        const response = await this.props.savePassword({token, password})
        if (response.error) {
            const error = response.error.message || 'Password reset failied.';
            this.setState({error})
        } else {
            this.setState({success: 'Password reset is successful.'})
        }
    }

    render() {
        const allErrors = this.props.allErrors || [];
        const inputProps = {
            allFields: this.props.allFields,
            allErrors
        }
        return (<Container id="resetContainer" fluid={true}>
            <ReduxModal footer={true} isModal={this.state.globalError} comp={this.alerts()} onClose={this.onClose} header="Error details"/>
            <Header/>
            <div className="reset_wrapper">
                <h3 className="reset_header">Reset Password</h3>
                <Col md={12}>
                    <ReduxInput autoComplete={'off'} {...inputProps} size={12} type="password" name={'password'} label={'Enter Password'}></ReduxInput>
                </Col>
                <Col md={12}>
                    <ReduxInput autoComplete={'off'} {...inputProps} size={12} type="password" name={'verifyPassword'} label={'Verify password'}></ReduxInput>
                </Col>
                <Col md={12}>
                    {this.state.success && <Alert color="success">{this.state.success}</Alert>}
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                </Col>
                <Col md={12}>
                    <Button disabled={this.props.appBusy} onClick={this.resetPassword} className={`text-osrit`} block={true} color={'primary'}>{'Save Password'}</Button>
                </Col>
            </div>
            <Footer/>
        </Container>)
    }
}

Reset = reduxForm({form: 'resetForm', validate})(Reset)

const mapStateToProps = state => {
    return {
        resetValues: _get(state, 'form.resetForm.values', {}),
        appBusy: _get(state, 'app.appBusy', false),
        allErrors: _get(state, 'form.resetForm.syncErrors', {}),
        allFields: _get(state, 'form.resetForm.fields', {})
    }
};
const mapDispatchToProps = dispatch => bindActionCreators({
    savePassword
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
