import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import { Container } from "reactstrap";
import EldContent from "./EldContent";
import "./eld.scss";
import TextWithTitle from "../common/TextWithTitle";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../constants";

class Eld extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <Container id="pricingWrapper" fluid={true}>
                <Banner />
                <Helmet><title>{APP_NAME} | ELD Compliance</title></Helmet>
                <Header curr={"eld"} />
                <TextWithTitle
                    title="ELD Integrations"
                    text="Osrit has integrated leading with most of ELD service providers. Our integration helps you connect to your ELD provider to monitor drivers and equipments in real time."
                />
                <TextWithTitle
                    text="Our trusted ELD partner is Smartelds."
                />
                <EldContent />
            </Container>
        );
    }
}

export default Eld;
