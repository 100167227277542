import React from "react";
import { Row, Col } from "reactstrap";

const itemTitle = {
    fontSize: 20,
    fontWeight: 600,

};

const itemDetail = {
    fontSize: 14,
    marginTop: 15,
    fontWeight: 300
};

const allItem = [
    {
        title: "Real-Time GPS Tracking",
        detail:
            "Track in real time. Everything is accessed over the web and mobile APP.",
        image: "realtime",
        alt: "Realtime vehicle gps tracker"
    },
    {
        title: "Daily Route Maps",
        detail: "View all the journeys and routes taken on a day.",
        image: "route",
        alt: "daily google routes map osrit trucking software"
    },
    {
        title: "Live Alert Notifications",
        detail: "Be alerted to just the events you consider important.",
        image: "alerts",
        alt: "trucking Live Alert Notifications"
    },
    {
        title: "Create Geo-fences",
        detail: "Complete control and protect your assets and business.",
        image: "geofence",
        alt: "free geofencing software for trucks"
    },
    {
        title: "Driver Behavior",
        detail:
            "See speeding, harsh acceleration & braking, sharp turn, unnecessary idling, etc.",
        image: "driverBehaviour",
        alt: "truck drivers behaviour and attitude"
    },
    {
        title: "Reports & Analytics",
        detail:
            "Data-rich reports generate analytics to help save you time and money.",
        image: "reports",
        alt: "Trucks Driver Reports & Analytics"
    }
];
const RenderItem = ({ data }) => {
    return (

        <div
            className="price_container"
            style={{
                padding: 20,
                border: 0,
                backgroundColor: "transparent",
                boxShadow: "#c2c6ca 0px 0px 10px",
                textAlign: "center"
            }}
        >
            <div style={{ minHeight: 100, marginBottom: 10 }}>
                <img
                    alt={data.alt}
                    style={{
                        maxHeight: 100,
                        minHeight: 100
                    }}
                    src={require(`../../images/gps/${data.image}.png`)}
                />

            </div>
            <div style={{ minHeight: 100 }}>
                <div style={itemTitle}>{data.title}</div>
                <div style={itemDetail}>{data.detail}</div>
            </div>
        </div>
    );
};

class PricingContent extends React.Component {
    onSignUp = () => { };
    render() {
        return (

            <Row className="gps_container_wrapper" style={{ margin: 0 }}>
                {allItem.map((item, i) => {
                    return (
                        <Col data-aos="fade-right-up"
                            data-aos-delay={300 * i}
                            key={i} md={4} style={{ padding: 25 }}>
                            <RenderItem data={item} />
                        </Col>
                    );
                })}
            </Row>
        );
    }
}

export default PricingContent;
