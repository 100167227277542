import React from "react";
import { Col } from "reactstrap";


class LeftImageBox extends React.Component {
    render() {
        const { image, title, desc, desc2, alt, list, listSize } = this.props
        return (
            <section className="row image-box-container"
                style={{ margin: 0, backgroundColor: "white" }}>
                <Col />
                <Col data-aos="fade-right" sm={{ size: 'auto', offset: 1 }} md={{ size: 5, offset: 0 }}
                    style={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <img className="img-mobile-view" src={image} alt={alt} />
                </Col>
                <Col data-aos="fade-right" md="5" sm="12" style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    display: "flex",
                    alignItems: "center",
                }}>
                    <div className="text-center" style={{ width: "100%" }}>
                        {title && <h1 className="text-center h1" style={{ width: "100%", marginBottom: 15, fontSize: 28 }}>{title}</h1>}
                        {desc && <div className="text" style={{ marginBottom: 15, justifyContent: "center", textAlign: 'justify' }}>{desc}</div>}
                        {desc2 && <div className="text" style={{ marginBottom: 15, justifyContent: "center", textAlign: 'justify' }}>{desc2}</div>}
                        {list && Array.isArray(list) && list.length > 0 && (
                            <div style={{ marginLeft: 30, textAlign: "justify", fontSize: 14 }}>
                                {list.map((item, index) => {
                                    return (
                                        <li data-aos="fade-up-left"
                                            data-aos-delay={600 * index}
                                            key={index}
                                            style={{
                                                fontSize: listSize || 16, lineHeight: 1.6,
                                                textAlign: "justify"
                                            }}>
                                            {item}
                                        </li>)
                                })}
                            </div>
                        )}
                    </div>
                </Col><Col />
            </section>
        )
    }
}

export default LeftImageBox;
