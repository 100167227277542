const validate = (values = {}) => {

  const errors = {}

  if (!values.firstName) {
    errors.firstName = 'First name is required.'
  } else if (values.firstName.length < 4) {
    errors.firstName = 'First name too short.'
  }

  if (!values.lastName) {
    errors.lastName = 'Last name is required.'
  } else if (values.lastName.length < 4) {
    errors.lastName = 'First name too short.'
  }

  if (!values.phoneNo) {
    errors.phoneNo = 'Phone number is required.'
  } else if (values.phoneNo.length < 10 || values.phoneNo.length > 11) {
    errors.phoneNo = 'Invalid phone number.'
  }

  if (!values.email) {
    errors.email = 'Email name is required.'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address.'
  }

  if (!values.message) {
    errors.message = 'Message is required.'
  } else if (values.message.length < 10) {
    errors.message = 'Message too short.'
  }

  return errors
}

export default validate;
