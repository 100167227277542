import React from 'react';
import {Field} from 'redux-form';
import {Col, FormGroup, Label, Input} from 'reactstrap';

class ReduxRadio extends React.Component {

    handleChange = () => {}

    render() {
        const {
            name,
            label,
            value,
            size = 12,
            disabled = false
        } = this.props;

        const styles = {
            paddingRight: 5,
            paddingLeft: 5,
            ...this.props.styles
        }

        return (<Col md={size} style={styles}>
            <FormGroup check={true}>
                <Label check={true}>
                  <Input disabled={disabled}
                    type="radio"
                    tag={Field}
                    id={name}
                    name={name}
                    value={value}
                    component={'input'}
                    style={{ marginTop: '.2rem', cursor: 'pointer' }}
                    onChange={this.handleChange}
                  />{' '}{label}
                </Label>
            </FormGroup>
        </Col>)
    }
}
export default ReduxRadio
