import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { goTo } from "../../actions/appActions";
import cardBkgOne from "../../images/card_bkg.jpg";
import cardBkgTwo from "../../images/card_bkg2.jpg";
import cardBkgThree from "../../images/card_bkg3.jpg";
import cardBkgFour from "../../images/card_bkg3.jpg";
import cardBkgFive from "../../images/card_bkg3.jpg";

class Card extends React.Component {
    render() {
        const locales = this.props.locales;
        const index = this.props.index;
        console.log(this.props)

        return (
            <Col md={4} sm={12} xs={12} className="home_card_col">
                <div
                    data-aos="fade-up-right"
                    data-aos-delay={400 * index}
                    className="home_card"
                    style={{
                        backgroundColor: "#00000000",
                        backgroundSize: "cover"
                    }}
                >
                    <div className="home_card_icon"
                        data-aos-delay={400 * index}
                    >
                        <img src={require(`../../images/icons/${this.props.icon}.png`)}
                            alt={this.props.alt || locales.alt}
                            aria-hidden="true"
                        />
                    </div>
                    <div className="home_card_header text-center">
                        {locales.header}
                    </div>
                    <div className="wrap">
                        <div className="home_card_title text-center">
                            {locales.title}
                        </div>
                        <div className="home_card_detail text-center" >
                            {locales.detail}
                        </div>
                    </div>
                    <div className="home_card_button_wrapper text-center">
                        <Link
                            to={this.props.page}
                            className="home_card_button btn btn-outline-secondary"
                        >
                            LEARN MORE
                        </Link>
                    </div>
                </div>
            </Col>
        );
    }
}

class HeroCards extends React.Component {
    render() {
        const locales = {
            carrier: {
                header: "Carriers",
                imageSrc: cardBkgOne,
                title: "Stay organized. Stay paid.",
                alt: "Stay organized saty paid",
                detail:
                    "Manage everything from IFTA to driver, owner operator settlements and invoicing, all within one web platform."
            },
            recruiting: {
                header: "Recruiting",
                imageSrc: cardBkgTwo,
                title: "Quick Driver Hiring",
                alt: "Quick Driver Hiring",
                detail: "Get all the necessary information of a driver and manage all the applications on a single page."
            },
            brokerage: {
                header: "Brokerage",
                imageSrc: cardBkgThree,
                title: "Streamline your back office. Earn more.",
                alt: "Streamline your back office. Earn more.",
                detail:
                    "Manage loads, carrier payables, agent offices, payroll, and invoicing to be professional and profitable."
            },
            accounting: {
                header: "Accounting",
                imageSrc: cardBkgFour,
                title: "One click payables and receivables.",
                alt: "One click payables and receivables.",
                detail:
                    "After creating a load, the invoice is ready for you and with assigning driver or carrier settlement is ready."
            },
            tracking: {
                header: "GPS Tracking",
                imageSrc: cardBkgOne,
                title: "Easily track your tractor or driver.",
                alt: "Easy track tractor or driver.",
                detail:
                    "You can easily connect your existing ELD, GPS Tracker to get realtime tracking information or use our mobile app to track driver."
            },
            agreements: {
                header: "Compliance",
                imageSrc: cardBkgFive,
                title: "Stay on top of compliance.",
                alt: "Stay on top of compliance with agreements",
                detail:
                    "Osrit Safety not only offers solutions to assist you in maintaining compliance but we deliver the news you need to know!"
            }
        };
        return (
            <Row style={{ margin: 0 }}>
                <Col sm="12" md={{ size: 10, offset: 1 }}>
                    <Row className="home_card_wrapper justify-content-center" style={{ margin: 0 }}>
                        <Card
                            page="/carrier"
                            icon={"Carriers"}
                            locales={locales.carrier}
                            index={1}
                        />
                        <Card
                            page="/brokerage"
                            icon={"Brokerage"}
                            index={2}
                            locales={locales.brokerage}
                        />
                        <Card
                            page="/recruiting"
                            icon={"Recruiting"}
                            index={3}
                            locales={locales.recruiting}
                        />
                        <Card data-aos="fade-down"
                            data-aos-delay="600"
                            page="/accounting"
                            index={1}
                            icon={"Accounting"}
                            locales={locales.accounting}
                        />
                        <Card data-aos="fade-down"
                            data-aos-delay="600"
                            page="/gps"
                            index={2}
                            icon={"GPSTracking"}
                            locales={locales.tracking}
                        />
                        <Card data-aos="fade-down"
                            data-aos-delay="600"
                            page="/compliance"
                            index={3}
                            icon={"Compliance"}
                            locales={locales.agreements}
                        />
                    </Row>
                </Col>
            </Row >
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            goTo
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeroCards);
