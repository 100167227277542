import React from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    Alert,
    Form
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, SubmissionError} from 'redux-form';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import ReduxInput from '../../components/ReduxInput';
import ReduxSelect from '../../components/ReduxSelect';
import ReduxRadio from '../../components/ReduxRadio';
import {me, onBoarding} from '../../actions/appActions';
import {states} from '../../constants';
import validate from './validate'

class RegisterForm extends React.Component {

    state = {
        saving: false,
        error: '',
        success: '',
        dotnoError: false,
        phoneError: false,
        line1Error: false,
        cityError: false,
        zipError: false,
        businessnameError: false,
        globalError: false
    }

    componentDidMount() {
        this.props.me();
    }

    getPayload = () => {
        const params = this.props.registerValues || {};
        return {
            name: params.businessname,
            accountType: params.accountType,
            busalias: {
                businesstype: 'corp',
                line1: params.line1,
                line2: params.line2,
                city: params.city,
                state: params.state,
                zip: params.zip,
                country: 'US',
                fax: params.fax,
                mcno: params.mcno,
                dotno: params.dotno,
                email: params.email,
                phone: params.phone
            }
        }
    }

    save = async () => {

        this.setState({error: false});

        if (!_isEmpty(this.props.allErrors)) {
            return new SubmissionError();
        }

        this.setState({saving: true});
        return this.saveBusiness();
    }

    saveBusiness = async () => {
        const payload = this.getPayload();
        const response = await this.props.onBoarding(payload);

        this.setState({saving: false});

        if (response && response.business && (response.business.message === 'DONE')) {
            this.props.redirectToPage('/login');
            return;
        }

        if (response && response.error && response.error.message) {
            if (response.error.code === 'ER_DUP_ENTRY') {
                this.setState({error: 'Business is already registered, please try reset password.'})
            } else {
                this.setState({error: response.error.message})
            }

        } else {
            this.setState({error: 'Something went wrong, please try after some time.'})
        }
    }

    handleFocus = (e) => {
        const name = e.currentTarget.name;
        this.setState({
            [`${name}Error`]: false,
            registerError: ''
        })
    }

    render() {
        const handleSubmit = this.props.handleSubmit || {};
        const allErrors = this.props.allErrors || [];
        const inputProps = {
            allFields: this.props.allFields,
            allErrors
        }

        return (<div>
            <Card className={'form-register-wrapper'}>
              <Form onSubmit={handleSubmit(this.save)}>
                <CardBody>
                    <CardTitle className={'text-center text-uppercase'}>Business Details</CardTitle>
                    <Row>
                        <Col md={8} className="col-osrit">
                            <ReduxInput {...inputProps} icon={'th'} size={12} name={'businessname'} label={'Business name'}></ReduxInput>
                        </Col>
                        <Col md={4} className="col-osrit">
                            <ReduxInput {...inputProps} icon={'th'} size={12} name={'dotno'} label={'DOT number'}></ReduxInput>
                        </Col>
                        <Col md={12} className="col-osrit">
                            <ReduxInput disabled={true} icon={'envelope'} size={6} name={'email'} label={'Email'} handleFocus={this.handleEmailFocus}></ReduxInput>
                        </Col>
                        <Col md={12} className="col-osrit">
                            <ReduxInput {...inputProps} icon={'phone'} size={6} name={'phone'} type="number" label={'Phone number'}></ReduxInput>
                        </Col>
                        <Col md={8} className="col-osrit">
                            <ReduxInput {...inputProps} size={9} name={'line1'} label={'Street address'}></ReduxInput>
                        </Col>
                        <Col md={4} className="col-osrit">
                            <ReduxInput name={'line2'} label={'Suit#'}></ReduxInput>
                        </Col>
                        <Col md={6} className="col-osrit">
                            <ReduxInput {...inputProps} size={5} name={'city'} label={'City'}></ReduxInput>
                        </Col>
                        <ReduxSelect size={3} name={'state'} label={'State'} placeholder={'State'} items={states} labelKey={'value'} labelKeyTwo={'country'}></ReduxSelect>
                        <Col md={3} className="col-osrit">
                            <ReduxInput {...inputProps} size={4} name={'zip'} label={'Zip'}></ReduxInput>
                        </Col>
                        <Col md={12} className="col-osrit">
                            <ReduxRadio size="12" name="accountType" value={'discounted'} label={'Get 50% off for first 6 months ($49.00/month).'}/>
                            <ReduxRadio size="12" name="accountType" value={'trial'} label={'Free trial for 30 days and then $99.00/month.'}/>
                        </Col>

                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        <Col md={12}>
                            <Button size="sm" className="osrit_btn" disabled={this.state.saving} block={true} style={{
                                    margin: '15px auto'
                                }} type={'submit'}>
                                {this.state.saving && <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>}
                                {
                                    this.state.saving
                                        ? ' Saving...'
                                        : ' SAVE'
                                }
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
              </Form>
            </Card>
        </div>)
    }
}

RegisterForm = reduxForm({form: 'registerForm', validate, enableReinitialize: true})(RegisterForm);

const mapStateToProps = state => ({
    user: state.app.user,
    registerValues: _get(state, 'form.registerForm.values'),
    allErrors: _get(state, 'form.registerForm.syncErrors', {}),
    allFields: _get(state, 'form.registerForm.fields', {})
});

const mapDispatchToProps = dispatch => bindActionCreators({
    me,
    onBoarding
}, dispatch);

RegisterForm = connect(state => {
    let initialValues = _get(state, 'app.user.user', {})

    initialValues.state = 'CA';
    initialValues.subscription = initialValues.subscription || {};
    initialValues.accountType = 'trial';

    return {initialValues}
})(RegisterForm);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
