// const lowerCaseLetters = /[a-z]/g;
const upperCaseLetters = /[A-Z]/g;
// const numbers = /[0-9]/g;

const isUppercase = (value) => {
    return !value.match(upperCaseLetters)
}
const validate = (values = {}) => {

    const errors = {}
    const password = values.password;
    const verifyPassword = values.verifyPassword;

    if (!values.token) {
        errors.token = 'Token is required.'
    }

    if (!password) {
        errors.password = 'Password is required.'
    } else if (password.length < 6) {
        errors.password = 'Password too short.'
    } else if (isUppercase(password)) {
        errors.password = 'Capital (uppercase) letter is required'
    }

    if (!verifyPassword) {
        errors.verifyPassword = 'Verify password is required.'
    } else if (password !== verifyPassword) {
        errors.verifyPassword = 'Password does not match.'
    }

    return errors
}

export default validate;
