import React from "react";
import { Row, Col } from "reactstrap";
import IOS_IMAGE from "../../images/ios_store.png";
import ANDROID_IMAGE from "../../images/google_play.png";

class HomeContact extends React.Component {
    render() {
        return (
            <Row
                style={{
                    marginLeft: 0, marginRight: 0,
                    backgroundColor: "#007fe40d",
                    paddingTop: 32, paddingBottom: 32
                }}
            >
                <Col data-aos="fade-right" md="7" style={{
                    padding: 40,
                    display: "flex",
                    alignItems: "center"
                }}>
                    <div data-aos="fade-right" data-aos-delay="1000" className="text-center" style={{ width: "100%" }}>
                        <section><h1 className="text-center"
                            style={{ width: "100%", marginBottom: 15 }}>Mobile Applications</h1></section>
                        <div className="text" style={{ marginBottom: 15, justifyContent: "center", textAlign: 'center' }}>{
                            "Easily manage your bussiness with our mobile apps. Access your all loads, expense, etc. on Live Dispatch App. Get real time updates by mobile app and stay connected to your bussiness. Live Dispatch is available for both iOS and Android. "
                        }</div>
                    </div>

                </Col>
                <Col xs={12} md={5}
                    style={{
                        padding: 20,
                        display: "flex",
                        alignItems: "center"
                    }}>
                    <div data-aos="fade-right" data-aos-delay="1000"
                        className="text-center item item--secondary"
                        data-aos-anchor="#trigger-left"
                        style={{ width: "100%" }}>
                        <div style={{ textAlign: "center", width: "100%" }}                        >
                            <a
                                href="https://play.google.com/store/apps/details?id=com.asritsolutions.dispatch"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img
                                    src={ANDROID_IMAGE}
                                    alt="ANDROID Osrit | Live Dispatch"
                                    style={{
                                        maxWidth: 250,
                                        margin: "auto"
                                    }}
                                />
                            </a>
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                width: "100%"
                            }}
                        >
                            {" "}
                            <a
                                href="https://apps.apple.com/us/app/live-dispatch/id1459782483"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img
                                    src={IOS_IMAGE}
                                    alt="IOS Osrit | Live Dispatch "
                                    style={{
                                        maxWidth: 250,
                                        margin: "auto"
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>


        );
    }
}

export default HomeContact;
