export const bussType = [
    {
        "value": "llc",
        "name": "Limited Liability Company"
    }, {
        "value": "cpt",
        "name": "Cooperative"
    }, {
        "value": "corp",
        "name": "Corporation"
    }, {
        "value": "part",
        "name": "Partnership"
    }, {
        "value": "scorp",
        "name": "S Corporation"
    }, {
        "value": "sprop",
        "name": "Sole Proprietorship"
    }
]

export const timeZones = [
    {
        "value": "America/New_York",
        "name": "Eastern Standard Time (EST)"
    }, {
        "value": "America/Chicago",
        "name": "Central Standard Time (CST)"
    }, {
        "value": "America/Denver",
        "name": "Mountain Standard Time (MST)"
    }, {
        "value": "America/Los_Angeles",
        "name": "Pacific Standard Time (PST)"
    }
]

export const countries = [
    {
        "name": "United States",
        "value": "US"
    }, {
        "name": "Canada",
        "value": "CA"
    }
]

export const states = [
    {
        "name": "Alabama",
        "value": "AL",
        "country": "US"
    }, {
        "name": "Alaska",
        "value": "AK",
        "country": "US"
    }, {
        "name": "American Samoa",
        "value": "AS",
        "country": "US"
    }, {
        "name": "Arizona",
        "value": "AZ",
        "country": "US"
    }, {
        "name": "Arkansas",
        "value": "AR",
        "country": "US"
    }, {
        "name": "California",
        "value": "CA",
        "country": "US"
    }, {
        "name": "Colorado",
        "value": "CO",
        "country": "US"
    }, {
        "name": "Connecticut",
        "value": "CT",
        "country": "US"
    }, {
        "name": "Delaware",
        "value": "DE",
        "country": "US"
    }, {
        "name": "District Of Columbia",
        "value": "DC",
        "country": "US"
    }, {
        "name": "Federated States Of Micronesia",
        "value": "FM",
        "country": "US"
    }, {
        "name": "Florida",
        "value": "FL",
        "country": "US"
    }, {
        "name": "Georgia",
        "value": "GA",
        "country": "US"
    }, {
        "name": "Guam",
        "value": "GU",
        "country": "US"
    }, {
        "name": "Hawaii",
        "value": "HI",
        "country": "US"
    }, {
        "name": "Idaho",
        "value": "ID",
        "country": "US"
    }, {
        "name": "Illinois",
        "value": "IL",
        "country": "US"
    }, {
        "name": "Indiana",
        "value": "IN",
        "country": "US"
    }, {
        "name": "Iowa",
        "value": "IA",
        "country": "US"
    }, {
        "name": "Kansas",
        "value": "KS",
        "country": "US"
    }, {
        "name": "Kentucky",
        "value": "KY",
        "country": "US"
    }, {
        "name": "Louisiana",
        "value": "LA",
        "country": "US"
    }, {
        "name": "Maine",
        "value": "ME",
        "country": "US"
    }, {
        "name": "Marshall Islands",
        "value": "MH",
        "country": "US"
    }, {
        "name": "Maryland",
        "value": "MD",
        "country": "US"
    }, {
        "name": "Massachusetts",
        "value": "MA",
        "country": "US"
    }, {
        "name": "Michigan",
        "value": "MI",
        "country": "US"
    }, {
        "name": "Minnesota",
        "value": "MN",
        "country": "US"
    }, {
        "name": "Mississippi",
        "value": "MS",
        "country": "US"
    }, {
        "name": "Missouri",
        "value": "MO",
        "country": "US"
    }, {
        "name": "Montana",
        "value": "MT",
        "country": "US"
    }, {
        "name": "Nebraska",
        "value": "NE",
        "country": "US"
    }, {
        "name": "Nevada",
        "value": "NV",
        "country": "US"
    }, {
        "name": "New Hampshire",
        "value": "NH",
        "country": "US"
    }, {
        "name": "New Jersey",
        "value": "NJ",
        "country": "US"
    }, {
        "name": "New Mexico",
        "value": "NM",
        "country": "US"
    }, {
        "name": "New York",
        "value": "NY",
        "country": "US"
    }, {
        "name": "North Carolina",
        "value": "NC",
        "country": "US"
    }, {
        "name": "North Dakota",
        "value": "ND",
        "country": "US"
    }, {
        "name": "Northern Mariana Islands",
        "value": "MP",
        "country": "US"
    }, {
        "name": "Ohio",
        "value": "OH",
        "country": "US"
    }, {
        "name": "Oklahoma",
        "value": "OK",
        "country": "US"
    }, {
        "name": "Oregon",
        "value": "OR",
        "country": "US"
    }, {
        "name": "Palau",
        "value": "PW",
        "country": "US"
    }, {
        "name": "Pennsylvania",
        "value": "PA",
        "country": "US"
    }, {
        "name": "Puerto Rico",
        "value": "PR",
        "country": "US"
    }, {
        "name": "Rhode Island",
        "value": "RI",
        "country": "US"
    }, {
        "name": "South Carolina",
        "value": "SC",
        "country": "US"
    }, {
        "name": "South Dakota",
        "value": "SD",
        "country": "US"
    }, {
        "name": "Tennessee",
        "value": "TN",
        "country": "US"
    }, {
        "name": "Texas",
        "value": "TX",
        "country": "US"
    }, {
        "name": "Utah",
        "value": "UT",
        "country": "US"
    }, {
        "name": "Vermont",
        "value": "VT",
        "country": "US"
    }, {
        "name": "Virgin Islands",
        "value": "VI",
        "country": "US"
    }, {
        "name": "Virginia",
        "value": "VA",
        "country": "US"
    }, {
        "name": "Washington",
        "value": "WA",
        "country": "US"
    }, {
        "name": "West Virginia",
        "value": "WV",
        "country": "US"
    }, {
        "name": "Wisconsin",
        "value": "WI",
        "country": "US"
    }, {
        "name": "Wyoming",
        "value": "WY",
        "country": "US"
    },
    {
        "value": "AB",
        "name": "Alberta",
        "country": "CA"
    }, {
        "value": "BC",
        "name": "British Columbia",
        "country": "CA"
    }, {
        "value": "MB",
        "name": "Manitoba",
        "country": "CA"
    }, {
        "value": "NB",
        "name": "New Brunswick",
        "country": "CA"
    }, {
        "value": "NL",
        "name": "Newfoundland and Labrador",
        "country": "CA",
        "alt": ["Newfoundland", "Labrador"]
    }, {
        "value": "NS",
        "name": "Nova Scotia",
        "country": "CA"
    }, {
        "value": "NU",
        "name": "Nunavut",
        "country": "CA"
    }, {
        "value": "NT",
        "name": "Northwest Territories",
        "country": "CA"
    }, {
        "value": "ON",
        "name": "Ontario",
        "country": "CA"
    }, {
        "value": "PE",
        "name": "Prince Edward Island",
        "country": "CA"
    }, {
        "value": "QC",
        "name": "Quebec",
        "country": "CA"
    }, {
        "value": "SK",
        "name": "Saskatchewan",
        "country": "CA"
    }, {
        "value": "YT",
        "name": "Yukon",
        "country": "CA"
    }
]


export const APP_NAME = process.env.REACT_APP_NAME
export const isLive = process.env.REACT_APP_DOMAIN === '.osrit.com'