import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

class ReduxModal extends React.Component {
    static defaultProps = {}

    handleDone = (e) => {
        e.preventDefault();
        this.props.handleDone(false)
    }

    toggle = () => {
        this.props.onClose()
    }

    render() {
        let styles = {};

        if (this.props.large) {
            styles.width = '95%';
            styles.maxWidth = '95%';
        }
        return (<Modal style={styles} isOpen={this.props.isModal} toggle={this.toggle}>
            {this.props.showHeader && <ModalHeader toggle={this.toggle}>{this.props.header}</ModalHeader>}
            <ModalBody>{this.props.comp}</ModalBody>
            {
                this.props.footer && <ModalFooter>
                        <Button style={{
                                margin: 'auto'
                            }} color="secondary" onClick={this.toggle}>DONE</Button>
                    </ModalFooter>
            }
        </Modal>);
    }
}

export default ReduxModal;
