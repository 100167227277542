import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import ReduxTextarea from './../../components/ReduxTextarea';
import {
    Card,
    CardBody,
    Row,
    Col,
    Button,
    Alert,
    CardTitle
} from 'reactstrap';
import ReduxModal from './../../components/ReduxModal';
import ReduxInput from '../../components/ReduxInput';
import validate from './validate'
import { contactUs } from '../../actions/appActions';
import './contact.scss';

class Contact extends React.Component {

    state = {
        error: false,
        success: false
    }

    componentDidMount() { }

    handleClick = async () => {
        if (!_isEmpty(this.props.allErrors)) {
            this.setState({ globalError: true });
            return;
        }
        const contact = this.props.contact || {};
        if (!contact.firstName) {
            return
        }
        const payload = {
            app: "OSRIT",
            fullname: `${contact.firstName} ${contact.lastName}`,
            email: contact.email,
            phonenumber: contact.phoneNo,
            subject: 'Contact us from www.osrit.com',
            message: contact.message,
            dateadded: new Date()
        }
        const response = await this.props.contactUs(payload);

        const isSuccess = _get(response, 'body.status') === 'success';
        this.setState({
            success: isSuccess,
            error: !isSuccess
        })
    }

    onClose = () => {
        this.setState({ globalError: false, isReset: false })
    }

    alerts = () => {
        const allErrors = this.props.allErrors;
        return (<Alert color="">
            {
                allErrors && Object.keys(allErrors).map((item, i) => (<div key={i} style={{
                    fontSize: 14,
                    marginTop: 5
                }}>
                    <i className="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>{' '}
                    {allErrors[item]}</div>))
            }
        </Alert>)
    }

    render() {
        const allErrors = this.props.allErrors || [];
        const inputProps = {
            allFields: this.props.allFields,
            allErrors
        }
        return (<div id="contactWrapper">
            <ReduxModal footer={true} isModal={this.state.globalError} comp={this.alerts()} onClose={this.onClose} header="Error details" />
            <Card className={'form-contact-wrapper '}>
                <CardTitle className={'text-center'}>Contact Us</CardTitle>
                <CardBody>
                    <Row>
                        <Col md={6} className="col-osrit">
                            <ReduxInput {...inputProps} handleFocus={this.handleFocus} hideLabel={true} name={'firstName'} label={'First name'}></ReduxInput>
                        </Col>
                        <Col md={6} className="col-osrit">
                            <ReduxInput {...inputProps} handleFocus={this.handleFocus} hideLabel={true} name={'lastName'} label={'Last name'}></ReduxInput>
                        </Col>
                        <Col md={12} className="col-osrit">
                            <ReduxInput {...inputProps} handleFocus={this.handleFocus} hideLabel={true} name={'email'} label={'Email'}></ReduxInput>
                        </Col>
                        <Col md={12} className="col-osrit">
                            <ReduxInput {...inputProps} handleFocus={this.handleFocus} hideLabel={true} name={'phoneNo'} label={'Phone #'}></ReduxInput>
                        </Col>
                        <Col md={12} className="col-osrit">
                            <ReduxTextarea {...inputProps} handleFocus={this.handleFocus} type={'textarea'} hideLabel={true} name={'message'} label={'Enter message'}></ReduxTextarea>
                        </Col>
                        <Col md={12} className="col-osrit text-center">
                            {this.state.success && <Alert color="success">Message is submitted successfully. We will contact you shortly.</Alert>}
                            {this.state.error && <Alert color="danger">Something went wrong, please try after some time.</Alert>}
                        </Col>
                        <Col md={12} className="text-center col-osrit">
                            <Button disabled={this.props.appBusy} onClick={this.handleClick}
                                className="contact_us_btn" block={true}
                                style={{ backgroundColor: "#009ddd" }}
                            >CONTACT US</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>);
    }
}

const mapStateToProps = state => {
    return {
        contact: _get(state, 'form.contactForm.values', {}),
        allErrors: _get(state, 'form.contactForm.syncErrors', {}),
        allFields: _get(state, 'form.contactForm.fields', {}),
        appBusy: _get(state, 'appData.appBusy', false)
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    contactUs
}, dispatch)

Contact = reduxForm({ form: 'contactForm', validate })(Contact)

Contact = connect(state => ({
    initialValues: _get(state, 'appData.contact', {})
}))(Contact)

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
