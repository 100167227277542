import React from 'react';
import {
    Card,
    CardBody,
    Button,
    Col,
    Alert,
    Form
} from 'reactstrap';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { bindActionCreators } from 'redux';
import ReduxInput from '../../components/ReduxInput';
import ReduxCheckBox from '../../components/ReduxCheckBox';
import { signUp, signIn } from '../../actions/appActions';
import Header from '../Header';
import logo from '../../images/logo.png'
import validate from './validate';
import AppRoutes from '../../AppRoutes';
import './signUp.scss';

class SignUp extends React.Component {

    state = {}
    signIn = async (username, password) => {
        await this.props.signIn({ username, password });
        this.props.history.push(AppRoutes.register)
    }

    logIn = () => {
        this.props.history.push(AppRoutes.login);
    }

    signUp = async (values) => {
        if (!_isEmpty(this.props.allErrors)) {
            return new SubmissionError();
        }

        const email = values.email;
        const password = values.password;
        const payload = {
            email,
            password,
            terms: values.terms,
            lastname: values.lastname,
            firstname: values.firstname,
            businessname: values.businessname,
        }

        const response = await this.props.signUp(payload);

        if (response && response.user && response.user.id) {
            return this.signIn(email, password);
        }

        if (response && response.error && response.error.message) {
            if (response.error.message === ' is invalid') {
                const message = _get(response, 'error.details.messages');
                this.setState({ registerError: JSON.stringify(message) });
            } else {
                this.setState({ registerError: response.error.message })
            }
            return
        }

        if (response && response.error) {
            this.setState({ registerError: 'Something went wrong, please try after some time.' })
        }

    }

    render() {
        const handleSubmit = this.props.handleSubmit || {};
        const signUp = this.props.signUpValues || {};
        const allErrors = this.props.allErrors || [];
        const inputProps = {
            allFields: this.props.allFields,
            allErrors
        }

        return (<div id="signUpWrapper">
            <Header />
            <Card className={'form-signup-wrapper'}>
                <Form onSubmit={handleSubmit(this.signUp)}>
                    <CardBody>
                        <div className={'text-center'}>
                            <img style={{
                                width: 75,
                                margin: 30
                            }} src={logo} alt={'Osrit'} />
                        </div>
                        <h6 className={'text-center'}>Register to Osrit account</h6>
                        <ReduxInput {...inputProps} icon={'user'} size={6} name={'firstname'} label={'First name'}></ReduxInput>
                        <ReduxInput {...inputProps} icon={'user'} size={6} name={'lastname'} label={'Last name'}></ReduxInput>
                        <ReduxInput {...inputProps} icon={'th'} size={12} name={'businessname'} label={'Company name'}></ReduxInput>
                        <ReduxInput {...inputProps} icon={'envelope'} size={12} name={'email'} label={'Email'} ></ReduxInput>
                        <ReduxInput {...inputProps} icon={'key'} size={12} name={'password'} type={'password'} label={'Password'} isPassword={true}></ReduxInput>
                        <ReduxCheckBox {...inputProps} size={12} value={signUp.terms} name={'terms'} label={'Terms'} />
                        {this.state.registerError && <Alert color="danger">{this.state.registerError}</Alert>}
                        <Col md={12}>
                            <Button size={'sm'} className="osrit_btn" disabled={this.props.appBusy} block={true} style={{
                                margin: '15px auto'
                            }} type={'submit'}>
                                {
                                    this.props.appBusy
                                        ? 'Saving...'
                                        : 'Sign Up'
                                }
                            </Button>
                        </Col>
                        <Col md={12}>
                            <Button onClick={this.logIn} className={`text-osrit`} block={true} color={'link'}>{'Log In'}</Button>
                        </Col>
                    </CardBody>
                </Form>
            </Card>
        </div>)
    }
}

SignUp = reduxForm({ form: 'signUpForm', validate })(SignUp)

const mapStateToProps = state => {
    return {
        signUpValues: _get(state, 'form.signUpForm.values', {}),
        appBusy: _get(state, 'app.appBusy', false),
        allErrors: _get(state, 'form.signUpForm.syncErrors', {}),
        allFields: _get(state, 'form.signUpForm.fields', {})
    }
};
const mapDispatchToProps = dispatch => bindActionCreators({
    signUp,
    signIn
}, dispatch)

SignUp = connect(state => ({
    initialValues: _get(state, 'app.user', {})
}))(SignUp)

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
