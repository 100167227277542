import { get, post } from "../http";
import * as TYPES from "./appActionTypes";
import { history } from "../store";

export function appBusy(value) {
    return { type: TYPES.APP_BUSY, value };
}

export function updateUser(value) {
    return { type: TYPES.USER, value };
}

export function goTo(url = "/app") {
    return (dispatch, getState) => {
        history.push(url);
    };
}

export function me() {
    return (dispatch, getState) => {
        dispatch(appBusy(true));
        return get("/api/auth/check", {})
            .then(response => {
                dispatch(updateUser(response));
                return response;
            })
            .catch(err => {
                return err;
            })
            .then(response => {
                dispatch(appBusy(false));
                return response;
            });
    };
}

export function signIn(params) {
    return (dispatch, getState) => {
        dispatch(appBusy(true));
        return post("/signin", params)
            .then(response => {
                return response;
            })
            .catch(err => {
                return err;
            })
            .then(response => {
                dispatch(appBusy(false));
                return response;
            });
    };
}

export function signUp(params) {
    return (dispatch, getState) => {
        dispatch(appBusy(true));
        return post("/api/auth/register", params)
            .then(response => {
                return response;
            })
            .catch(err => {
                return err;
            })
            .then(response => {
                dispatch(appBusy(false));
                return response;
            });
    };
}

export function onBoarding(params) {
    return (dispatch, getState) => {
        dispatch(appBusy(true));
        return post("/api/business", params)
            .then(response => {
                return response;
            })
            .catch(err => {
                return err;
            })
            .then(response => {
                dispatch(appBusy(false));
                return response;
            });
    };
}

export function resetPassword(params) {
    return (dispatch, getState) => {
        dispatch(appBusy(true));
        return post("/api/auth/reqreset", params)
            .then(response => {
                return response;
            })
            .catch(err => {
                return err;
            })
            .then(response => {
                dispatch(appBusy(false));
                return response;
            });
    };
}

export function contactUs(params) {
    return (dispatch, getState) => {
        dispatch(appBusy(true));
        return post("https://osritapi.com/api/issues/contactus", params)
            .then(response => {
                return response;
            })
            .catch(err => {
                return err;
            })
            .then(response => {
                dispatch(appBusy(false));
                return response;
            });
    };
}

export function savePassword(params) {
    return (dispatch, getState) => {
        dispatch(appBusy(true));
        return post("/api/auth/reset", params)
            .then(response => {
                return response;
            })
            .catch(err => {
                return err;
            })
            .then(response => {
                dispatch(appBusy(false));
                return response;
            });
    };
}
