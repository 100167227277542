import React from 'react';
import { Col } from 'reactstrap';

class RowThree extends React.Component {
    render() {
        return (<section className="row home_row_three">
            <Col md={12}>
                <h1 className="header text-center" style={{ color: "black" }}>
                    Get ready for a stress-free experience.
                </h1>
            </Col>
        </section>)
    }
}

export default RowThree;
