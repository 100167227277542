import React, { useState } from "react";
import image from "../../images/banner.jpg";
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from "reactstrap";

const items = [
    {
        src: image,
        caption:
            "Osrit Cloud Software is not just limited to softwares, you can get every thing at one place including ELD's, Dash Cams, GPS Trackers etc.",
        altText: "Osrit | Trucking Management"
    },
    {
        src: image,
        caption:
            "Osrit Cloud Software helps you easily dispatch FTL or LTL loads, customers, brokers, drivers, payroll, invoicing, etc from one place !",
        altText: "Osrit | Trucking Management Software"
    },
    {
        src: image,
        caption:
            "Easily manage driver hiring with Osrit Software. Get all the necessary information of a driver and manage all the applications on a single page",
        altText: "Osrit | Recruiting"
    },
    {
        src: image,
        caption:
            "Osrit Cloud Software provides you ready-made agreements that can help you in every aspect of your business.",
        altText: "Osrit | Compliance"
    },
    {
        src: image,
        caption:
            "Osrit Cloud Software has full featured add-in for the dump trucking industry.",
        altText: "Osrit | Dump Dispatch Software"
    }
];

const Example = props => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item, i) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={`${i}`}
            >
                <div style={{ maxHeight: 380 }}>
                    <img
                        src={item.src}
                        style={{ width: "100%", maxHeight: "100%" }}
                        alt={item.altText}
                    />

                    <div
                        style={{
                            backgroundColor: "rgba(0, 0, 0, 0.45)",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%"
                        }}
                    />
                </div>

                <CarouselCaption
                    captionText={item.altText}
                    captionHeader={item.caption}
                />
            </CarouselItem>
        );
    });

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={3000}
        >
            <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
            />
            <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
            />
        </Carousel>
    );
};

export default Example;
