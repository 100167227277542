import React from "react";
import { Col } from "reactstrap";

class RightImageBox extends React.Component {
    render() {
        const { image, title, desc, desc2, alt, list } = this.props
        return (
            <section className="row image-box-container"
                style={{
                    margin: 0,
                    backgroundColor: "#007fe40d"
                }} >
                <Col />
                <Col data-aos="fade-right" sm={{ size: 'auto', offset: 1 }} md={{ size: 5, offset: 0 }}
                    style={{
                        textAlign: "center",
                        paddingTop: 20,
                        paddingBottom: 20,
                        display: "flex",
                        alignItems: "center"
                    }}>
                    <div className="text-center" style={{ width: "100%" }}>
                        <h1 className="text-center h1" style={{ width: "100%", fontSize: 30 }}>{title}</h1>
                        <div className="text" style={{ marginBottom: 15, justifyContent: "center", textAlign: 'justify' }}>{desc}</div>
                        <div className="text" style={{ marginBottom: 15, justifyContent: "center", textAlign: 'justify' }}>{desc2}</div>
                        {list && Array.isArray(list) && list.length > 0 && (
                            <div style={{ marginLeft: 30, textAlign: "justify", fontSize: 14, }}>
                                {list.map((item, index) => {
                                    return (<li data-aos="fade-up-left"
                                        data-aos-delay={600 * index} style={{ lineHeight: 1.6, textAlign: "justify" }}>{item}</li>)
                                })}
                            </div>
                        )}
                    </div>
                </Col>
                <Col data-aos="fade-right" md="5" sm="12" style={{
                    display: "flex", alignItems: "center"
                }}>
                    <img className="img-mobile-view" src={image} alt={alt} />
                </Col>
                <Col />
            </section>
        )
    }
}
export default RightImageBox;
