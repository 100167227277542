import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import { Container } from "reactstrap";
import { Helmet } from 'react-helmet';
import "./dispatch.scss";
import LeftImageBox from "../common/LeftImageBox";
import RightImageBox from "../common/RightImageBox";
import elmanage from "../../images/dispatch/easyloadmanage.svg";
import detailedload from "../../images/dispatch/detailload.svg";
import realtimeupdate from "../../images/dispatch/realtimeload.svg";
import weather from "../../images/dispatch/weather.svg";
import tracking from "../../images/dispatch/locationtracking.svg";
import expenserev from "../../images/dispatch/expenserevenue.svg";
import drivermanage1 from "../../images/dispatch/drivermanage1.svg";
import vehiclemanage from "../../images/dispatch/vehiclemanage.svg";
import customreport from "../../images/dispatch/customreport.svg";
import TextWithTitle from "../common/TextWithTitle";
import { APP_NAME, isLive } from "../../constants";

class Dispatch extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <Container
                id="dispatchWrapper"
                style={{ padding: 0 }}
                fluid={true}
            >
                <Helmet>
                    <title>Dispatch Management Software - {APP_NAME}</title>
                    {isLive && <meta name="description" content="Dispatch Management Software -OsriT is an easy to use mobile app that helps truckers manage their routes, deliveries and more." />}
                </Helmet>
                <Banner />
                <Header curr={"dispatch"} />
                <TextWithTitle title="Planning & Dispatch"
                    text="Beginning with a strong core of functionality that every trucking company needs, the Osrit system comes with the ability to add multiple functional modules, fully tested integration products for other transportation management and trucking industry solutions ranging from mobile communications to vehicle maintenance management."
                />
                <RightImageBox
                    image={elmanage}
                    title="Easy Load management"
                    alt="Load management software"
                    desc=" Osrit Software helps you to manage all your loads easily, whether its FTL or LTL. You can easily manage and dispatch multiple loads from single or multiple customers on a single truck with the help of Osrit software. You can easily manage loads payment, delivery status, single driver or team driving, single or multiple pickups or drops and many more for each of your loads on single page." />
                <LeftImageBox
                    maxHeight="220px"
                    image={detailedload}
                    title="Detailed Load information"
                    alt="Dispatch Software"
                    desc="Each load may have a lot of data to manage and with the help of Osrit software you can see detailed information for each load. You can easily check how many pickups and drops are there in a single load or whether there is single or team driving for each load. Also, you can see information of the company, factoring, vehicle and weather forecast for each of your loads." />
                <RightImageBox
                    image={weather}
                    title="Weather Reports"
                    alt="Weather Report"
                    desc="Weather reports are very important in trucking. It is not possible for humans to control weather and to overcome that, Osrit Software will provide you 10 days weather forecasting so, you can plan your loads accordingly. Osrit Software gives you 10-day weather forecast for each of your pickups and drops addresses." />

                <LeftImageBox
                    image={realtimeupdate}
                    title="Realtime Load updates"
                    alt="Realtime Update"
                    desc="Load updates are really important when it comes to trucking business. It’s impossible to control human error or Mother Nature, however, Osrit Software will give you an update when there will be any delay so that you can further plan other things accordingly. Also, with Osrit Software you can specify whether you want to receive notification or not for each load and you can easily turn on and off your notification" />

                <RightImageBox
                    image={tracking}
                    title="Location Tracking"
                    alt="Fleet GPS tracking"
                    desc="It is very important to track the location of vehicles in transport business. Osrit Software helps you to track freights that are on road to helps you easily plan your future loads. With real-time tracking of your freight you can make better decisions for your company and it will also help you to provide better customer service." />
                <LeftImageBox
                    image={vehiclemanage}
                    title="Vehicle Management"
                    alt="Fleet management"
                    desc="Osrit can help you in managing the information of all your tractors and trailers in one place. You can easily view and update information of License, Lien, service, etc. for any of your vehicle with one click. You can also upload vehicle documents to our cloud storage so that you can manage them in real time from anywhere. Some of the benefits are listed below:"
                    list={["REPORTS", "TRACKING", "DOCUMENT"]} />
                <RightImageBox
                    image={drivermanage1}
                    title="Driver Management"
                    alt="Driver Management Software"
                    desc="Managing driver is as important as Vehicle Management. Osrit can help you in managing the information of all your driver. You can easily view and update information of drivers license, medical, etc. You can also easily manage all documents for each of your drivers with our cloud storage." />
                <LeftImageBox
                    image={customreport}
                    title="Custom Report"
                    alt="State Mileage report"
                    desc="Osrit provides the feature of custom reports. These reports can help you to easily understand each and every aspect of your business. You can check whether you're gaining profit from a particular client our not and also compare reports of different vehicles to check how much you are earning from each of your vehicles. You can easily create the reports of your business, drivers, trucks etc. Some of the reports are listed below:"
                    list={["PROFIT & LOSS", "DRIVER PAYMENT", "STATE MILEAGE", "TRUCK MILEAGE"]} />
                <RightImageBox
                    image={expenserev}
                    title="Revenue / Expense Management"
                    alt="trucking Revenue, Expense Allocation"
                    desc="Osrit makes it easier to track all the revenues and expenses. You can easily add all of your expenses and incomes generated from different sources to calculate the profit/loss of statement. You can create reports on monthly, quarterly and yearly bases or you can even create report for custom time frame according to your need to have better understanding of your revenues and expenses." />
            </Container>
        );
    }
}

export default Dispatch;
