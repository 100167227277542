import React from "react";
import { Row, Col } from "reactstrap";

const STYLES = {
    titleStyles: { fontSize: 20, fontWeight: 400, textTransform: "uppercase" },
    subTitleStyles: {
        fontSize: 12,
        fontWeight: 300,
        marginTop: 5,
        minHeight: 35
    }
};

const RenderApp = ({ item }) => (
    <div
        className=""
        style={{
            padding: 20,
            border: 0,
            minHeight: 245,
            backgroundColor: "transparent",
            boxShadow: "#c2c6ca 0px 4px 10px",
            textAlign: "center",
            borderRadius: "8px",
        }}
    >
        <div style={{ minHeight: 75, marginBottom: 10, marginTop: 15 }}>
            <img
                alt={item.title}
                style={{
                    maxHeight: 50,
                    minHeight: 50,
                    maxWidth: "90%"
                }}
                src={item.logoUrl}
            />
        </div>

        <div style={{ minHeight: 60 }}>
            <div style={STYLES.titleStyles}>{item.title}</div>
            <div style={STYLES.subTitleStyles}>{item.subTitle}</div>
        </div>
        <a
            href={item.href}
            className="btn btn-sm btn-primary"
            style={{ width: 150, backgroundColor: "#0587e1" }}
        >
            LOG IN
        </a>
    </div>
);

const appApps = [
    {
        title: "Dispatch Board",
        subTitle: "App for owners, accountants and dispatchers.",
        href: "https://www.app.osrit.com/",
        logoUrl: "http://localhost:3000/static/media/logo.df133739.png"
    },
    {
        title: "Partner Board",
        subTitle: "App for partners to manage their customers.",
        href: "https://admin.osrit.com/",
        logoUrl: "http://localhost:3000/static/media/logo.df133739.png"
    },

    {
        title: "Support Dashboard",
        subTitle: "App for support team to view their assigned tickets.",
        href: "https://osrit.com/login",
        logoUrl: "http://localhost:3000/static/media/logo.df133739.png"
    },
    {
        title: "Compliance Board",
        subTitle: "App for compliance services",
        href: "https://compliance.osrit.com/dashboard",
        logoUrl: "http://localhost:3000/static/media/logo.df133739.png"
    },
    {
        title: "ELD",
        subTitle: "ELD Compliance",
        href: "https://eldbooks.com/",
        logoUrl: "http://eldbooks.com/static/media/ELD_BOOKS_LOGO.6fd4fe46.png"
    },
    {
        title: "Dashcam Board",
        subTitle: "App for Dashcam",
        href: "https://www.dashcam.osrit.com/dashboard",
        logoUrl: "http://localhost:3000/static/media/logo.df133739.png"
    },
    {
        title: "GPS Tracker Board",
        subTitle: "App for GPS tracking",
        href: "https://www.gps.osrit.com/dashboard",
        logoUrl: "http://localhost:3000/static/media/logo.df133739.png"
    },


];

class PricingContent extends React.Component {
    onSignUp = () => { };
    render() {
        return (
            <Row className="price_container_wrapper">
                {appApps.map((item, i) => (
                    <Col key={i} md={4} style={{ padding: 25 }}>
                        <RenderApp item={item} />
                    </Col>
                ))}
            </Row>
        );
    }
}

export default PricingContent;
