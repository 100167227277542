import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import AppRoutes from '../../AppRoutes';
import {Link} from 'react-router-dom';
import successImg from '../../images/success.png';
import './verified.scss';

class Verified extends React.Component {
    render() {
        return (<div id="verifiedWrapper">
            <Header/>
            <div className="verified_container">
                <img src={successImg} alt="Success" style={{
                        height: 150,
                        margin: '50px auto'
                    }}/>
                <h3>YOUR EMAIL IS SUCCESSFULLY VERIFED.</h3>
                <Link to={AppRoutes.login} className="login_btn">{'Log In'}</Link>
            </div>
            <Footer/>
        </div>)
    }
}

export default Verified;
