import React from "react";
import { Row, Col, Button } from "reactstrap";

class Products extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: props.products || []
        }
    }

    renderCard = (props) => {
        let { selected } = this.state
        const isSelected = selected.includes(props.title)
        return (
            <Row
                className={isSelected ? "selected_icon" : "icon_card  "}
                onClick={() => {
                    if (isSelected) {
                        selected = selected.filter((item) => item !== props.title)

                    } else {
                        selected.push(props.title)
                    }

                    this.setState({ selected })
                }}
            >
                <Col className="card_img" xs="2" md="12" >
                    <i className={`fa fa-${props.icon} fa-2x`} aria-hidden="true"></i>
                </Col>
                <Col md="12" xs="10" >
                    <div className="verticle_align"> {props.title}</div></Col>
            </Row>
        )

    }
    render() {
        const { selected } = this.state
        return (
            <div data-aos="fade-up-left">
                <h2 className="h2tag">Features or products you interested in </h2>
                <div className="cardplace">
                    <Row>
                        <Col data-aos="fade-right" sm={12} md={3} lg="3" style={{ padding: 10 }} >
                            {this.renderCard(
                                { title: "ELD Compliance", slug: "ELD", icon: "truck", id: "1" }
                            )}
                        </Col>
                        <Col />
                        <Col data-aos="fade-right" sm={12} md={3} lg="3" style={{ padding: 10 }}>
                            {this.renderCard(
                                { title: "GPS Tracking", icon: "map-marker", id: "2" }
                            )}

                        </Col>
                        <Col />

                        <Col data-aos="fade-right" sm={12} md={3} lg="3" style={{ padding: 10 }}>
                            {this.renderCard(
                                { title: "Smart Dashcam", icon: "camera", id: "3" }
                            )}
                        </Col>
                        <Col />



                    </Row>
                    <Row>
                        <Col data-aos="fade-right" sm={12} md={3} lg="3" style={{ padding: 10 }}>
                            {this.renderCard(

                                { title: "Trailer Tracking", icon: "map", id: "3" })}

                        </Col>
                        <Col />

                        <Col data-aos="fade-right" sm={12} md={3} lg="3" style={{ padding: 10 }}>
                            {this.renderCard(
                                { title: "Compliance Board", icon: "list", id: "5" })}

                        </Col>
                        <Col />

                        <Col data-aos="fade-right" sm={12} md={3} lg="3" style={{ padding: 10 }}>
                            {this.renderCard(
                                { title: "Dispatch Board", icon: "paper-plane", id: "6" })}

                        </Col>
                        <Col />

                    </Row>
                    <Row>
                        <Col data-aos="fade-right" sm={12} md={3} lg="3" style={{ padding: 10 }}>
                            {this.renderCard(
                                { title: "Vehicle Maintenance", icon: "cogs", id: "7" })}
                        </Col>
                        <Col />


                        <Col data-aos="fade-right" sm={12} md={3} lg="3" style={{ padding: 10 }}>
                            {this.renderCard(
                                { title: "Reefer Temprature Monitoring", icon: "thermometer", id: "8" })}

                        </Col>
                        <Col />

                        <Col data-aos="fade-right" sm={12} md={3} lg="3" style={{ padding: 10 }}>
                            {this.renderCard(
                                { title: "Custom Trucking Solution", icon: "question-circle ", id: "9" })}
                        </Col>
                        <Col />

                    </Row>


                </div>
                <div className="button_placement">
                    <Button color="primary" style={{ marginTop: 10, paddingLeft: 28, paddingRight: 28, paddingTop: 6, paddingBottom: 6 }}
                        disabled={selected.length === 0}
                        onClick={() => { this.props.setStepAndData(2, selected, "") }}
                    >
                        NEXT
                    </Button>
                </div>
            </div >
        );
    }
}

export default Products;
