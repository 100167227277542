const validate = (values = {}) => {

    const errors = {}

    if (!values.businessname) {
        errors.businessname = 'Business is required.'
    } else if (values.businessname.length < 4) {
        errors.businessname = 'Business too short.'
    }

    if (!values.dotno) {
        errors.dotno = 'DOT number is required.'
    } else if (values.dotno.length < 4) {
        errors.dotno = 'DOT number is not valid.'
    }

    if (!values.phone) {
        errors.phone = 'Phone number is required.'
    } else if (values.phone.length < 4) {
        errors.phone = 'Phone number is not valid.'
    }

    if (!values.line1) {
        errors.line1 = 'Address is required.'
    } else if (values.line1.length < 4) {
        errors.line1 = 'Address is not valid.'
    }

    if (!values.city) {
        errors.city = 'City is required.'
    } else if (values.city.length < 2) {
        errors.city = 'City is not valid.'
    }

    if (!values.state) {
        errors.state = 'State is required.'
    } else if (values.state.length < 2) {
        errors.state = 'State is not valid.'
    }

    if (!values.zip) {
        errors.zip = 'Zip is required.'
    } else if (values.zip.length < 2) {
        errors.zip = 'Zip is not valid.'
    }

    return errors
}

export default validate;
