import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import { Container } from "reactstrap";
import Products from "./Products";
import Vehicles from "./Vehicles";
import Form from "./Form";
import "./pricing.scss";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../constants";

class Pricing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            step: 1,
            products: [],
            vehicles: ""
        };
    }
    setStepAndData = (step, products, vehicles) => {
        this.setState({ step, products, vehicles })
        console.log(vehicles)
    }
    render() {
        const MAX_LENGTH = 3
        const { step, products, vehicles } = this.state
        return (
            <Container id="pricingWrapper" fluid={true}>
                <Banner />
                <Header curr={"pricing"} />
                <Helmet><title>{APP_NAME} | Pricing</title></Helmet>
                <h5 className="step">{`Step ${step} of ${MAX_LENGTH}`} </h5>
                {step === 1 ? <Products setStepAndData={this.setStepAndData} products={products} /> : null}
                {step === 2 ? <Vehicles setStepAndData={this.setStepAndData} products={products} vehicles={vehicles} /> : null}
                {step === 3 ? <Form setStepAndData={this.setStepAndData} products={products} vehicles={vehicles} /> : null}
            </Container>
        );
    }
}

export default Pricing;
