import React from "react";
import { Row, Col } from "reactstrap";
import { ITEMS } from "./data";

const STYLES = {
    titleStyles: {
        marginTop: 20

    },
    itemStyles: {
        fontSize: 18,
        fontWeight: 300
    }
};
const RenderContent = ({ item }) => {
    return (
        <Col md={4} style={{ padding: 12 }}>
            <div data-aos="fade-right-up"
                data-aos-delay={150 * 2}
                className="price_container"
                style={{
                    padding: "20px 20px",
                    border: 0,
                    backgroundColor: "transparent",
                    boxShadow: "#c2c6ca 0px 0px 10px",
                    textAlign: "center"
                }}
            >
                <div >
                    <div style={{ minHeight: 75, margin: "10px auto" }}>
                        <img src={require(`../../images/icons/${item.icon}.png`)}
                            alt={item.alt}
                            aria-hidden="true"
                        />
                    </div>
                    <section style={{ minHeight: 150 }}>
                        <h3 style={STYLES.titleStyles}>{item.title}</h3>
                        <div style={STYLES.itemStyles}>{item.detail}</div>
                    </section>
                </div>
            </div>
        </Col>
    );
};

class CarrierContent extends React.Component {
    onSignUp = () => { };
    render() {
        return (
            <Row className="price_container_wrapper" style={{ padding: 25, margin: 0 }}>
                {ITEMS.map((item, i) => (
                    <RenderContent key={i} item={item} />
                ))}
            </Row>
        );
    }
}

export default CarrierContent;
