import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import TextWithTitle from "../common/TextWithTitle";
import { Container, Button } from "reactstrap";
import CarrierContent from "./CarrierContent";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../constants";

class Carrier extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <Container
                style={{
                    padding: 0
                }}
                fluid={true}
            >
                <Banner />
                <Header />
                <Helmet><title>{APP_NAME} | Carrier</title></Helmet>
                <TextWithTitle title="Carrier"
                    text="Osrit is well designed TMS for carrier to ease and automate day to day activities. Stay on top of your trucking business with our smart artificial intelligence based cloud solution." />
                <CarrierContent />
                <div
                    style={{
                        marginTop: 25,
                        textAlign: "center"
                    }}
                >
                    <Button
                        style={{ minWidth: 200 }}
                        color="primary"
                        onClick={e => {
                            e.preventDefault();
                            window.Calendly.initPopupWidget({
                                url: "https://calendly.com/osrit/30min"
                            });
                        }}
                    >
                        <i className="fa fa-phone-square" aria-hidden="true" />{" "}
                        {" LET'S TALK"}
                    </Button>
                </div>
            </Container >
        );
    }
}

export default Carrier;
