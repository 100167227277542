import React from 'react';
import classNames from 'classnames';
import {Field} from 'redux-form';
import {FormGroup, Label, Tooltip, FormFeedback} from 'reactstrap';
import _get from 'lodash/get';

const STYLES = {
  ERROR: {
    display: 'block',
    fontSize: 12,
    color: '#F44336'
  },
  ERROR_INPUT: {
    border: '1px solid #F44336'
  }
}

class ReduxTextarea extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showHelp: false
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.tooltipToggle = this.tooltipToggle.bind(this);
  }

  static defaultProps = {
    handleBlur: () => {},
    onKeyUp: () => {},
    handleFocus: () => {}
  }

  tooltipToggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  handleHelp(status) {
    if (this.props.help) {
      this.setState({showHelp: status});
    }
  }

  handleBlur(e) {
    this.handleHelp(false);
    this.props.handleBlur(e.target.value);
  }

  handleFocus(e) {
    this.handleHelp(true);
    this.props.handleFocus(this.props.name);
  }

  handleKeyUp(e) {
    this.props.onKeyUp(e.target.value);
  }

  render() {
    const {
      placement = 'top',
      capitalize,
      disabled = false
    } = this.props;
    const capClass = classNames({'text-capitalize': capitalize});
    const showLabel = !this.props.hideLabel;
    const placeholder = this.props.placeholder || this.props.label;
    const fieldName = this.props.id || this.props.name.replace(/\./g, '_');

    const isTouched = _get(this, `props.allFields[${fieldName}].visited`, false);
    const fieldError = _get(this, `props.allErrors[${fieldName}]`, false);
    const invalid = !!(isTouched && fieldError)

    let inputStyles = {
      width: '100%',
      padding: '6px 12px',
      fontSize: 14,
      color: '#555',
      backgroundColor: '#fff',
      backgroundImage: 'none',
      border: '1px solid #ccc',
      borderRadius: 4
    };

    if (fieldError && invalid) {
      inputStyles.border = '1px solid #F44336';
    }

    return (<FormGroup>
      {
        showLabel && <Label htmlFor={this.props.name} className={capClass}>
            {this.props.label}{' '}
            {this.props.tooltip && <i className="fa fa-info-circle text-primary" id={fieldName}/>}
          </Label>
      }
      {
        this.props.tooltip && <Tooltip placement={placement} isOpen={this.state.tooltipOpen} target={fieldName} toggle={this.tooltipToggle}>
            {this.props.tooltip}
          </Tooltip>
      }
      <Field disabled={disabled} style={inputStyles} className="input" name={fieldName} component="textarea" type="text" placeholder={placeholder}/>
      {invalid && <FormFeedback style={STYLES.ERROR}>{fieldError}</FormFeedback>}
    </FormGroup>)
  }
}
export default ReduxTextarea
