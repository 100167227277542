import React from 'react';
import { Row, Col } from 'reactstrap';
import Contact from '../Contact/Contact';

class HomeContact extends React.Component {
    render() {
        return (<Row style={{
            margin: 0,
            backgroundColor: 'black',
            color: 'white'
        }}>
            <Col xs={12} md={6}>
                <section style={{ margin: '150px auto 50px', maxWidth: 350 }}>
                    <h3 className="">Asritsolutions LLC</h3>
                    <div style={{
                        marginTop: 20
                    }}>
                        <i className="fa fa-address-card" color="#009ddd" aria-hidden="true"></i>{' '}4900 Hopyard Rd Ste 100
                    </div>
                    <div style={{
                        paddingLeft: 24,
                        marginBottom: 10
                    }}>Pleasanton, CA 94588</div>
                    <div style={{
                        marginBottom: 10
                    }}>
                        <i className="fa fa-phone" color="#009ddd" aria-hidden="true"></i>{' '}
                        <a className="a_" href="tel:+1 (408) 520-0014"> +1 (408) 520 - 0014 </a>
                    </div>
                    <div style={{
                        marginBottom: 10
                    }}>
                        <i className="fa fa-envelope" color="#009ddd" aria-hidden="true"></i>{' '}
                        <a className="a_" href="mailto:contactus@asritsolutions.com">contactus@asritsolutions.com</a>
                    </div>
                    <div>
                        <a target="_blank" href="https://www.facebook.com/OsritTruckingSoftware/?ref=pages_you_manage" className='social' rel="noreferrer">
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                        <a target="_blank" href="https://www.instagram.com/osritsoftware" className='social' rel="noreferrer">
                            <i className="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                        <a target="_blank" href="https://twitter.com/OsritSoftware" className='social' rel="noreferrer">
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/company/osrit-trucking-software" className='social' rel="noreferrer">
                            <i className="fa fa-linkedin" aria-hidden="true"></i>
                        </a>


                    </div>
                </section>
            </Col>
            <Col style={{
                color: 'black'
            }} xs={12} md={6}>
                <Contact />
            </Col>
        </Row>)
    }
}

export default HomeContact;
