const validate = (values = {}) => {

    const errors = {}

    if (!values.firstname) {
        errors.firstname = 'First name is required.'
    } else if (values.firstname.length < 4) {
        errors.firstname = 'First name too short.'
    }

    if (!values.lastname) {
        errors.lastname = 'Last name is required.'
    } else if (values.lastname.length < 4) {
        errors.lastname = 'Last name too short.'
    }

    if (!values.businessname) {
        errors.businessname = 'Company name is required.'
    } else if (values.businessname.length < 4) {
        errors.businessname = 'Company name too short.'
    }

    if (!values.email) {
        errors.email = 'Email name is required.'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address.'
    }

    if (!values.password) {
        errors.password = 'Password is required.'
    } else if (values.password.length < 4) {
        errors.password = 'Password too short.'
    }

    if (!values.terms) {
        errors.terms = 'Terms are required.'
    }

    return errors
}

export default validate;
