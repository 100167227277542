import React from "react";
import Banner from "../Banner";
import { Container } from "reactstrap";
import Content from "./Content";
import "./allApps.scss";
import TextWithTitle from "../common/TextWithTitle";

class AllApps extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <Container id="pricingWrapper" fluid={true}>
                <Banner />
                <TextWithTitle title="" text="" />
                <Content />
            </Container>
        );
    }
}

export default AllApps;
