import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import RowZero from "./RowZero";
import RowTwo from "./RowTwo";
import RowThree from "./RowThree";
import RowFour from "./RowFour";
import Contact from "./Contact";
import MobileApps from "./MobileApps";
import "./home.scss";
import LeftImageBox from "../common/LeftImageBox";
import image from "../../images/home/mobile-tab-laptop.svg";
import img from "../../images/home/freetrial.svg";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../constants";

class Home extends React.Component {
    componentDidMount() {
        if (window.location.pathname === "/contact-us") {
            this.pageEnd.scrollIntoView({ behavior: "smooth" });
        }
    }

    render() {
        return (
            <div id="homeWrapper">
                <Helmet><title>{APP_NAME} | Cloud Trucking Software | TMS</title></Helmet>
                <Banner />
                <Header curr={"home"} />
                <RowZero />
                {/*<RowBanner />*/}
                <RowTwo />
                <LeftImageBox
                    image={image}
                    listSize={18}
                    alt="Dispatch Application"
                    list={["Available for Mobile(Android, iOS), PC (Windows, Mac) and Tablet."
                        , "Multiple users with different role and access",
                        "No additional software require.", "Access from anywhere."]} />
                <MobileApps />
                <LeftImageBox
                    image={img}
                    alt="Free Dispatch Software"
                    list={["One month free trial",
                        "No credit card details required", "Any time cancellation",
                        "Plan according your needs"]}
                    listSize={18}
                />

                <RowThree />
                <RowFour />
                {/* <div data-aos="slide-up"> */}
                <Contact />
                {/* </div> */}
                <div
                    style={{ float: "left", clear: "both" }}
                    ref={el => { this.pageEnd = el; }}
                />
            </div >
        );
    }
}

export default Home;
