import React from "react";
import Helmet from "react-helmet";
import { APP_NAME, isLive } from "../../constants";
import Banner from "../Banner";
import Header from "../Header";
import Content from "./Content";
import "./privacy.scss";
import queryString from "query-string";

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);

    const qp = queryString.parse(window.location.search);

    this.state = {
      isMobileApp: qp.view === "mobile",
    };
  }

  componentDidMount() {}

  render() {
    const isMobileApp = this.state.isMobileApp;
    return (
      <div id="privacyWrapper">
        <Helmet>
          <title>Privacy Policy - {APP_NAME}</title>
          {isLive && (
            <meta
              name="description"
              content="Osrit Trucking Software committed to protecting your privacy when using our software. Read our full privacy policy here and contact our support team today!"
            />
          )}
        </Helmet>

        {isMobileApp ? null : <Banner />}
        {isMobileApp ? null : <Header />}
        <Content />
      </div>
    );
  }
}

export default PrivacyPolicy;
