import * as cookie from "react-cookie";
import queryString from "query-string";
import React from "react";
import { Card, CardBody, CardTitle, Alert } from "reactstrap";
import { Button, Col, Row, Form } from "reactstrap";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { bindActionCreators } from "redux";
import ReduxInput from "./../../components/ReduxInput";
import ReduxModal from "./../../components/ReduxModal";
import { FormGroup, Label, Input } from "reactstrap";
import { signIn, resetPassword } from "../../actions/appActions";
import Header from "../Header";
import logo from "../../images/user.png";
import { getRedirectUrl, getLocation, getBrowser } from "../../utils/helpers";
import validate from "./validate";
import AppsModal from "./AppsModal";
import AppRoutes from "../../AppRoutes";
import "./signIn.scss";

const cookies = new cookie.Cookies();

function getDomain() {
    const url = window.location.hostname;
    let hostname = url.split(".").slice(-2);
    return hostname.length === 1 ? "localhost" : `.${hostname.join(".")}`;
}

console.log("DOMAIN ::::", getDomain());

class LogIn extends React.Component {
    state = {
        error: "",
        isToken: false,
        loading: false,
        globalError: false,
        tokenMess: "",
        usernameError: false,
        tokenError: false,
        passwordError: false,
        locationError: false,
        showAppsModal: false,
        userLocation: {},
        response: {}
    };

    componentDidMount() {
        const qp = queryString.parse(window.location.search);
        if (qp.f !== "l") {
            window.location.href = "https://www.auth.osrit.com/login";
        }
        else {
            getLocation()
                .then(userLocation => {
                    this.setState({ userLocation });
                })
                .catch(error => {
                    if (error.code === 1) {
                        this.setState({ locationError: true });
                    }
                });
        }
    }

    logIn = async () => {
        const token = _get(this, "props.logIn.token");
        const isToken = _get(this, "state.isToken", false);

        this.setState({ error: false });

        if (!_isEmpty(this.props.allErrors)) {
            this.setState({ globalError: true });
            return;
        }

        if (isToken && !token) {
            this.setState({ tokenError: true });
            return;
        }

        let payload = {
            ip: _get(this, "props.ip"),
            username: _get(this, "props.logIn.username"),
            password: _get(this, "props.logIn.password"),
            timestamp: _get(
                this,
                "state.userLocation.coords.timestamp",
                new Date()
            ),
            latitude: _get(
                this,
                "state.userLocation.coords.latitude",
                "37.7749295"
            ),
            longitude: _get(
                this,
                "state.userLocation.longitude",
                "-122.41941550000001"
            ),
            browser: getBrowser()
        };

        if (this.state.isToken) {
            payload.token = token;
        }

        this.setState({ loading: true });
        const response = await this.props.signIn(payload);

        // if (response.code === "SUCCESS" && !response.terms) {
        //     console.log(" *********** RENDER TERMS PAGE *********** ");
        //     this.setState({
        //         showAppsModal: true,
        //         response
        //     });
        // } else if (response.code === "SUCCESS") {
        if (response.code === "SUCCESS") {
            if (response.businessId) {
                this.setState({ signInResponse: response });
                this.handleNewDashboard(response);
            } else {
                this.props.history.push("/register");
            }
        } else if (response.code === "AUTH_CHALLENGE") {
            this.setState({
                isToken: true,
                loading: false,
                tokenMess: `Token has been send to xxx-xxx-${response.regPhone
                    }.`
            });
        } else {
            this.setState({
                loading: false,
                error: "Please check your username and password."
            });
        }
    };

    handleNewDashboard = response => {
        this.setCookie("token", response.token);
        this.setCookie("jwtToken", response.jwtToken);
        this.setCookie("businessId", response.businessId);
        this.setCookie("userId", response.userId);

        const newAppRole = [
            "owner",
            "dispacher",
            "accountant",
            "driver",
            "broker"
        ];

        const currentStatus = cookies.get("newDashboard");

        if (currentStatus === "accept") {
            this.toggleModal(currentStatus);
        } else if (currentStatus === "reject") {
            this.toggleModal(currentStatus);
        } else if (newAppRole.includes(response.userrole)) {
            this.setState({
                showAppsModal: true,
                response
            });
        } else {
            this.handleLoginSuccess();
        }
    };

    toggleModal = value => {
        this.setCookie("newDashboard", value);
        if (value === "accept") {
            const isHeroku = getDomain().includes("herokuapp");
            window.location.href = isHeroku
                ? "https://next.truckersoftwares.com"
                : "https://www.app.osrit.com";
        } else {
            this.handleLoginSuccess();
        }
    };

    handleLoginSuccess = () => {
        const response = this.state.signInResponse;
        const redirectUrl = getRedirectUrl();

        if (redirectUrl) {
            window.location.href = redirectUrl;
        } else {
            window.location.href = response.url;
        }
    };

    setCookie = (key, value) => {
        cookies.set(key, value, {
            path: "/",
            maxAge: 43200,
            domain: getDomain()
        });
    };

    signUp = () => {
        this.props.history.push(AppRoutes.signup);
    };

    onClose = () => {
        this.setState({ globalError: false, isReset: false });
    };

    alerts = () => {
        const allErrors = this.props.allErrors;
        return (
            <Alert color="">
                {allErrors &&
                    Object.keys(allErrors).map((item, i) => (
                        <div
                            key={i}
                            style={{
                                fontSize: 14,
                                marginTop: 5
                            }}
                        >
                            <i
                                className="fa fa-exclamation-triangle text-danger"
                                aria-hidden="true"
                            />{" "}
                            {allErrors[item]}
                        </div>
                    ))}
            </Alert>
        );
    };

    onReset = () => {
        this.setState({
            isReset: true,
            passwordSuccess: false,
            passwordError: false
        });
    };

    sendResetEmail = async () => {
        const email = _get(this, "props.logIn.email");
        if (!email) {
            this.setState({ passwordError: true });
            return;
        }

        await this.props.resetPassword({ email });
        this.setState({ passwordSuccess: true });
    };

    renderReset = () => {
        return (
            <Row>
                <Col md={12}>
                    <ReduxInput
                        showLabel={true}
                        size={12}
                        name={"email"}
                        label={"Email"}
                        handleFocus={this.handleEmailFocus}
                    />
                </Col>
                {this.state.passwordError && (
                    <Col md={12}>
                        <Alert color="danger" className="text-center">
                            <i
                                className="fa fa-exclamation-triangle text-danger"
                                aria-hidden="true"
                            />{" "}
                            Email is required.
                        </Alert>
                    </Col>
                )}
                {this.state.passwordSuccess && (
                    <Col md={12}>
                        <Alert color="success" className="text-center">
                            Please check your email for password reset
                            instructions.
                        </Alert>
                    </Col>
                )}
                <Col md={6}>
                    <Button
                        onClick={this.onClose}
                        className={`text-osrit`}
                        block={true}
                        color={"link"}
                    >
                        {"Cancel"}
                    </Button>
                </Col>
                <Col md={6}>
                    <Button
                        onClick={this.sendResetEmail}
                        className={`osrit_btn`}
                        block={true}
                    >
                        {this.props.appBusy && (
                            <i
                                className="fa fa-spinner fa-pulse fa-fw"
                                aria-hidden="true"
                            />
                        )}
                        {this.props.appBusy ? " Reseting..." : "Reset"}
                    </Button>
                </Col>
            </Row>
        );
    };

    renderLocationError = () => {
        return (
            <div style={{ margin: "25px auto" }}>
                <h4 className="text-center">LOCATION ERROR</h4>
                <p style={{ marginTop: 25 }}>
                    To use this feature, you must first "Enable geolocation
                    tracking"
                </p>
                <div style={{ marginTop: 25 }}>
                    <div className="text-center">
                        <a
                            href="https://www.clockspot.com/support/articles/how-to-enable-geolocation-tracking/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            How to Share your location
                        </a>
                    </div>
                    <div className="text-center" style={{ marginTop: 25 }}>
                        <small>
                            Note:- Please refresh screen after enabling
                            geolocation tracking.
                        </small>
                    </div>
                </div>
                <div className="mt-4 text-center">
                    <Button
                        size="sm"
                        color="link"
                        onClick={() => this.setState({ locationError: false })}
                    >
                        DISMISS
                    </Button>
                </div>
            </div>
        );
    };

    handleCheckBoxChange = event => {
        this.setState({ isToken: event.target.checked });
    };

    showPassword = () => {
        const { isPswText } = this.state;
        this.setState({ isPswText: !isPswText });
    };

    render() {
        const qp = queryString.parse(window.location.search);
        if (qp.f !== "l") {
            return null
        }

        const allErrors = this.props.allErrors || [];
        const inputProps = {
            allFields: this.props.allFields,
            allErrors
        };
        const { handleSubmit } = this.props;
        const { isPswText } = this.state;

        return (
            <div id="signInWrapper">
                <AppsModal
                    show={this.state.showAppsModal}
                    toggle={this.toggleModal}
                />
                <ReduxModal
                    footer={true}
                    isModal={this.state.globalError}
                    comp={this.alerts()}
                    onClose={this.onClose}
                    header="Error details"
                />
                <ReduxModal
                    isModal={this.state.isReset}
                    comp={this.renderReset()}
                    onClose={this.onClose}
                    showHeader={true}
                    header="Reset Password"
                />
                <ReduxModal
                    isModal={this.state.locationError}
                    comp={this.renderLocationError()}
                    onClose={this.onClose}
                    header="LOCATION ERROR"
                />
                <Header />
                <Card className={"form-signin-wrapper"}>
                    <CardBody>
                        <Form onSubmit={handleSubmit(this.logIn)}>
                            <div className={"text-center"}>
                                <img
                                    style={{
                                        width: 125,
                                        margin: 30
                                    }}
                                    src={logo}
                                    alt={"Osrit"}
                                />
                            </div>
                            <CardTitle className={"text-center"}>
                                Log in to Osrit account
                            </CardTitle>
                            <ReduxInput
                                {...inputProps}
                                autoComplete={"email"}
                                icon={"user"}
                                size={12}
                                name={"username"}
                                label={"Username"}
                            />
                            <ReduxInput
                                {...inputProps}
                                autoComplete={"password"}
                                icon={"key"}
                                size={12}
                                name={"password"}
                                type={isPswText ? "text" : "password"}
                                label={"Password"}
                            />

                            {this.state.isToken && (
                                <ReduxInput
                                    icon={"key"}
                                    size={12}
                                    name={"token"}
                                    type={"number"}
                                    label={"Token"}
                                />
                            )}
                            <Col md={12}>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    onChange={
                                                        this
                                                            .handleCheckBoxChange
                                                    }
                                                />{" "}
                                                Enter Token
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    onChange={this.showPassword}
                                                />{" "}
                                                Show Password
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            {this.state.isToken && this.state.tokenError && (
                                <Alert color="danger">
                                    {"Token is required."}
                                </Alert>
                            )}
                            {this.state.tokenMess && (
                                <Alert color="success">
                                    {this.state.tokenMess}
                                </Alert>
                            )}
                            {this.state.error && (
                                <Alert color="danger">{this.state.error}</Alert>
                            )}
                            <Col md={12}>
                                <Button
                                    className="osrit_btn"
                                    disabled={this.props.appBusy}
                                    block={true}
                                    style={{
                                        margin: "15px auto"
                                    }}
                                    type={"submit"}
                                >
                                    {this.state.loading && (
                                        <i
                                            className="fa fa-spinner fa-pulse fa-fw"
                                            aria-hidden="true"
                                        />
                                    )}
                                    {this.state.loading
                                        ? " Logging..."
                                        : "Log In"}
                                </Button>
                            </Col>
                            <Col md={12}>
                                <Row>
                                    <Col md={7}>
                                        <Button
                                            onClick={this.onReset}
                                            className={`text-osrit`}
                                            block={true}
                                            color={"link"}
                                        >
                                            {"Forget Password?"}
                                        </Button>
                                    </Col>
                                    <Col md={5}>
                                        <Button
                                            onClick={this.signUp}
                                            className={`text-osrit`}
                                            block={true}
                                            color={"link"}
                                        >
                                            {"Sign Up"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12}>
                                <div
                                    style={{
                                        fontSize: 12,
                                        marginTop: 20
                                    }}
                                    className="text-right text-secondary"
                                >
                                    Release {this.props.version}
                                </div>
                            </Col>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

LogIn = reduxForm({ form: "logInForm", validate })(LogIn);

const mapStateToProps = state => {
    return {
        ip: _get(state, "app.ip"),
        logIn: _get(state, "form.logInForm.values", {}),
        appBusy: _get(state, "app.appBusy", false),
        version: _get(state, "app.version"),
        allErrors: _get(state, "form.logInForm.syncErrors", {}),
        allFields: _get(state, "form.logInForm.fields", {})
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            signIn,
            resetPassword
        },
        dispatch
    );

LogIn = connect(state => ({
    initialValues: _get(state, "app.user", {})
}))(LogIn);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogIn);
