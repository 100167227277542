import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import { Card, Container, Row, Col } from "reactstrap";
import classes from './index.css';
import LeftImageBox from "../common/LeftImageBox";
import recruit from "../../images/recruiting.svg";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../constants";


const items = [
    "Steps to follow :",
    "1. Send the application link to driver via SMS.",
    "2. Driver can open the link in mobile or desktop.",
    "3. Driver can fill application.",
    "4. Once driver submit the application owner will receive notification.",
    "5. Owner will review the driver’s application.",
]

class Recruiting extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {

        return (
            <Container
                style={{ padding: 0, }}
                fluid={true}
            >
                <Banner />
                <Header />
                <Helmet><title>{APP_NAME} | Recruiting</title></Helmet>
                <LeftImageBox
                    image={recruit}
                    title="Recruiting"
                    alt="Driver Hiring Software"
                    desc="Osrit makes driver hiring very easy.Driver Hiring application’s process is easy both for driver and company. It is designed to get all the necessary information of driver in  easy way. This application is available for both mobile and desktop."
                />
                <div style={{
                    paddingTop: 0,
                    paddingBottom: 15,
                    margin: '50px auto',
                    maxWidth: 1000,
                    width: '100%',
                    fontSize: 18,
                    textAlign: 'center',
                }}>
                    For demo of driver application click on this <a href="https://recruiting.osrit.com/driver/123456/disclaimer">demo link </a>
                </div>

                <Row
                    style={{ marginBottom: 32, }}
                >


                    {items.map((item, i) => (
                        <Col sm="12" md={{ size: 6, offset: 3 }} key={i}>
                            <Card data-aos="fade-up-right"
                                data-aos-delay={400 * i} className={classes.card} style={{
                                    padding: 20,
                                    border: 0,
                                    backgroundColor: "white",
                                    boxShadow: '#c2c6ca 0px 0px 10px',
                                }}

                            >{item}</Card>
                        </Col>
                    ))}
                </Row>

            </Container>
        );
    }
}

export default Recruiting;
