import React from 'react';
import Banner from '../Banner';
import Header from '../Header';
import Content from './Content';
import './termsDownload.scss';
// import {Container, Row, Col} from 'reactstrap';

class TermsDownload extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (<div id="termsDownloadWrapper">
            <Banner />
            <Header />
            <Content />
        </div>)
    }
}

export default TermsDownload;
