import React from 'react';

class Equipment extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (<div>Equipment</div>)
    }
}

export default Equipment;
