import React from 'react';
import classNames from 'classnames';
import {Field} from 'redux-form';
import {
    FormGroup,
    Label,
    Input,
    FormText,
    Tooltip,
    FormFeedback
} from 'reactstrap';
import _get from 'lodash/get';

const STYLES = {
    ERROR: {
        fontSize: 12,
        color: '#F44336'
    },
    ERROR_INPUT: {
        fontSize: 12,
        color: '#F44336',
        border: '1px solid #F44336'
    },
    LABEL: {
        position: 'absolute',
        marginLeft: 10,
        marginTop: -1,
        fontSize: 12
    }
}
class ReduxInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showHelp: false
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.tooltipToggle = this.tooltipToggle.bind(this);
    }

    static defaultProps = {
        handleBlur: () => {},
        onKeyUp: () => {},
        handleFocus: () => {}
    }

    tooltipToggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    handleHelp(status) {
        if (this.props.help) {
            this.setState({showHelp: status});
        }
    }

    handleBlur(e) {
        this.handleHelp(false);
        this.props.handleBlur(e.target.value);
    }

    handleFocus(e) {
        this.handleHelp(true);
        this.props.handleFocus(this.props.name);
    }

    handleKeyUp(e) {
        this.props.onKeyUp(e.target.value);
    }

    render() {
        const {
            type = 'input',
            component = 'input',
            placement = 'top',
            capitalize,
            disabled = false,
            showLabel = false
        } = this.props;
        const capClass = classNames({'text-capitalize': capitalize});
        const placeholder = this.props.placeholder || this.props.label;
        const fieldName = this.props.id || this.props.name.replace(/\./g, '_');
        const isTouched = _get(this, `props.allFields[${fieldName}].touched`, false);
        const fieldError = _get(this, `props.allErrors[${fieldName}]`, false);
        const invalid = !!(isTouched && fieldError)
        const autoComplete =  this.props.autoComplete || `new-${this.props.name}`;

        let inputStyles = {};
        let inputProps = {};

        if (this.props.allFields) {
            inputProps.valid = !fieldError;
            if (!fieldError) {
                inputStyles.paddingTop = 20;
            }
        }

        if (isTouched && fieldError) {
            inputStyles = STYLES.ERROR_INPUT
        }

        return (<FormGroup>
            {
                inputProps.valid && <Label style={STYLES.LABEL} htmlFor={this.props.name} className={capClass}>
                        {this.props.label}{' '}
                        {this.props.tooltip && <i className="fa fa-info-circle text-primary" id={fieldName}/>}
                    </Label>
            }
            {
                showLabel && <Label htmlFor={this.props.name} className={capClass}>
                        {this.props.label}{' '}
                        {this.props.tooltip && <i className="fa fa-info-circle text-primary" id={fieldName}/>}
                    </Label>
            }
            {
                this.props.tooltip && <Tooltip placement={placement} isOpen={this.state.tooltipOpen} target={fieldName} toggle={this.tooltipToggle}>
                        {this.props.tooltip}
                    </Tooltip>
            }
            <Input {...inputProps} type={type} tag={Field} invalid={invalid} className={capClass} component={component} placeholder={placeholder} name={this.props.name} disabled={disabled} onBlur={this.handleBlur} onFocus={this.handleFocus} onKeyUp={this.handleKeyUp} style={inputStyles} autoComplete={autoComplete}/> {this.state.showHelp && <FormText>{this.props.help}</FormText>}
            {isTouched && fieldError && <FormFeedback style={STYLES.ERROR}>{fieldError}</FormFeedback>}
        </FormGroup>)
    }
}
export default ReduxInput
