import React from 'react';
import {Field} from 'redux-form';
import {Col, FormGroup, Label, Input} from 'reactstrap';
import _get from 'lodash/get';

class ReduxCheckBox extends React.Component {

    static defaultProps = {
        handleChange: () => {},
        handleFocus: () => {}
    }

    handleChange = () => {}

    handleFocus = (e) => {
        this.props.handleFocus(e)
    }

    handleTerms = () => {
        return (<div style={{
                position: 'absolute',
                marginTop: -26,
                marginLeft: 35,
                cursor: 'pointer'
            }}>I Agree to the{' '}
            <a href="https://osrit.com/terms" rel="noopener noreferrer" className="text-osrit" target="_blank">terms & conditions.</a>
        </div>)
    }

    render() {
        const {
            name,
            label,
            size = 12,
            disabled = false
        } = this.props;

        const styles = {
            paddingRight: 5,
            paddingLeft: 5,
            ...this.props.styles
        }

        const fieldName = this.props.id || this.props.name.replace(/\./g, '_');
        const isTouched = _get(this, `props.allFields[${fieldName}].touched`, false);
        const fieldError = _get(this, `props.allErrors[${fieldName}]`, false);
        const invalid = !!(isTouched && fieldError)

        return (<Col md={size} style={styles}>
            <FormGroup check={true}>
                <Label check={true}>
                    {
                        this.props.value
                            ? <i className="fa fa-check-square fa-2x text-osrit" aria-hidden="true"></i>
                            : <i className="fa fa-square fa-2x text-osrit-light-grey" aria-hidden="true"></i>
                    }

                    <Input disabled={disabled} type="checkbox" tag={Field} id={name} name={name} component={'input'} style={{
                            marginTop: '.2rem',
                            cursor: 'pointer',
                            opacity: 0
                        }} onFocus={this.handleFocus} onChange={this.handleChange}/>{' '}
                    {
                        name === 'terms'
                            ? this.handleTerms()
                            : label
                    }
                </Label>
                {invalid && <div className="text-danger">{fieldError}</div>}
            </FormGroup>
        </Col>)
    }
}
export default ReduxCheckBox
