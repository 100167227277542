import React from 'react';
import Contact from './Contact';
import Header from '../Header';
import './contact.scss';

class ContactWrapper extends React.Component {
    render() {
        return (<div id="">
            <Header selected="contact"/>
            <Contact/>
        </div>);
    }
}

export default ContactWrapper
