import React from "react";

class Content extends React.Component {
  render() {
    return (
      <div className="policy_content" style={{ textAlign: "justify" }}>
        <h3 className="text-center policy_header">Privacy Policy</h3> 
        <div>
          This Privacy Policy ("Policy") applies to <b>Live Dispatch</b>, <b>Osrit</b>, and
          <b> Asritsolutions LLC</b> ("Company") and governs data collection and usage.
          For the purposes of this Privacy Policy, unless otherwise noted, all
          references to the Company include 4900 Hopyard Rd. The Company's
          application is a We helps you easily dispatch FTL or LTL loads,
          customers, brokers, drivers, payroll, invoicing, etc from one place !
          application. By using the Company application, you consent to the data
          practices described in this statement.
        </div>
        <h4 style={{marginTop: 25}}>Collection of your Personal Information</h4>
        <div>
          In order to better provide you with products and services offered, the
          Company may collect personally identifiable information, such as your:
        </div>
         <div>-First and last name</div>
        <div>-Mailing address</div>
        <div>-Email address</div>
        <div>-Phone number</div>
        <div>-Location</div> 
        <div>
          We do not collect any personal information about you unless you
          voluntarily provide it to us. However, you may be required to provide
          certain personal information to us when you elect to use certain
          products or services. These may include: (a) registering for an
          account; (b) signing up for special offers from selected third
          parties; (c) sending us an email message; (d) submitting your credit
          card or other payment information when ordering and purchasing
          products and services. To wit, we will use your information for, but
          not limited to, communicating with you in relation to services and/or
          products you have requested from us. We also may gather additional
          personal or non-personal information in the future.
        </div>
        <h4 style={{marginTop: 25}}>Usage and Purpose Limitation</h4> 
        <div>Use of your Personal Information</div>
        <div>
          The Company collects and uses your personal information in the
          following ways:
        </div>
         <div>-to operate and deliver the services you have requested</div>
        <div>
          -to provide you with information, products, or services that you
          request from us
        </div>
        <div>-to provide you with notices about your account</div>
        <div>
          -to carry out the Company's obligations and enforce our rights arising
          from any contracts entered between you and us, including for billing
          and collection
        </div>
        <div>
          -to notify you about changes to our Driver Dispatch, Osrit or any
          products or services we offer or provide through it
        </div>
        <div>
          -in any other way we may describe when you provide the information
        </div>
        <div>-for any other purpose with your consent.</div> 
        <div>
          The Company may also use your personally identifiable information to
          inform you of other products or services available from the Company
          and its affiliates.
        </div>
        <div>
          Location information may be used to provide location-based services,
          personalize user experiences, and improve the quality of
          Asritsolutions's products and services.
        </div>
        <h4 style={{marginTop: 25}}>Sharing Information with Third Parties</h4>
        <div>
          The Company does not sell, rent, or lease its customer lists to third
          parties.
        </div>
         
        <div>
          The Company may, from time to time, contact you on behalf of external
          business partners about a particular offering that may be of interest
          to you. In those cases, your unique personally identifiable
          information (email, name, address, phone number) is transferred to the
          third party. The Company may share data with trusted partners to help
          perform statistical analysis, send you email or postal mail, provide
          customer support, or arrange for deliveries. All such third parties
          are prohibited from using your personal information except to provide
          these services tothe Company, and they are required to maintain the
          confidentiality of your information.
        </div>
         
        <div>
          The Company may disclose your personal information, without notice, if
          required to do so by law or in the good faith belief that such action
          is necessary to: (a) conform to the edicts of the law or comply with
          legal process served on the Company or the site; (b) protect and
          defend the rights or property of the Company; and/or (c) act under
          exigent circumstances to protect the personal safety of users of the
          Company, or the public.
        </div>
         
        <h4 style={{marginTop: 25}}>
          Opt-Out of Disclosure of Personal Information to Third Parties
        </h4>
        <div>
          In connection with any personal information we may disclose to a third
          party for a business purpose, you have the right to know:
        </div>
         
        <div>
          -The categories of personal information that we disclosed about you
          for a business purpose.
        </div>
         
        <div>
          You have the right under the California Consumer Privacy Act of 2018
          (CCPA) and certain other privacy and data protection laws, as
          applicable, to opt out of the disclosure of your personal information.
          If you exercise your right to opt out of the disclosure of your
          personal information, we will refrain from disclosing your personal
          information, unless you subsequently provide express authorization for
          the disclosure of your personal information. To opt out of the
          disclosure of your personal information, visit this web page
          https://www.osrit.com.
        </div>
        <h4 style={{marginTop: 25}}>Right to Deletion</h4>
        <div>
          Subject to certain exceptions set out below, on receipt of a
          verifiable request from you, we will:
        </div>
         <div>-Delete your personal information from our records; and</div>
        <div>
          -Direct any service providers to delete your personal information from
          their records.
        </div>
         
        <div>
          Please note that we may not be able to comply with requests to delete
          your personal information if it is necessary to:
        </div>
         
        <div>
          -Complete the transaction for which the personal information was
          collected, fulfill the terms of a written warranty or product recall
          conducted in accordance with federal law, and provide a good or
          service requested by you, or reasonably anticipated within the context
          of our ongoing business relationship with you, or otherwise perform a
          contract between you and us;
        </div>
        <div>
          -Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity; or prosecute those responsible for
          that activity;
        </div>
        <div>
          -Debug to identify and repair errors that impair existing intended
          functionality;
        </div>
        <div>
          -Exercise free speech, ensure the right of another consumer to
          exercise his or her right of free speech, or exercise another right
          provided for by law;
        </div>
        <div>
          -Comply with the California Electronic Communications Privacy Act;
        </div>
        <div>
          -Engage in public or peer-reviewed scientific, historical, or
          statistical research in the public interest that adheres to all other
          applicable ethics and privacy laws, when our deletion of the
          information is likely to render impossible or seriously impair the
          achievement of such research, provided we have obtained your informed
          consent;
        </div>
        <div>
          -Enable solely internal uses that are reasonably aligned with your
          expectations based on your relationship with us;
        </div>
        <div>-Comply with an existing legal obligation; or</div>
        <div>
          -Otherwise use your personal information, internally, in a lawful
          manner that is compatible with the context in which you provided the
          information.
        </div>
        <h4 style={{marginTop: 25}}>Opt Out and Unsubscribe from Third-Party Communications</h4>
        <div>
          We respect your privacy and give you an opportunity to opt out of
          receiving announcements of certain information. Users may opt out of
          receiving any or all communications from third-party partners of the
          Company by contacting us here:
        </div>
         <div>-Web page: https://www.osrit.com</div>
        <div>-Email: support@asritsolutions.com</div>
        <div>-Phone: 18887400937</div> <div>Email Communications</div>
        <div>
          From time to time, the Company may contact you via email for the
          purpose of providing announcements, promotional offers, alerts,
          confirmations, surveys, and/or other general communication.
        </div>
        <h4 style={{marginTop: 25}}>Changes to This Statement</h4>
        <div>
          The Company reserves the right to change this Policy from time to
          time. For example, when there are changes in our services, changes in
          our data protection practices, or changes in the law. When changes to
          this Policy are significant, we will inform you. You may receive a
          notice by sending an email to the primary email address specified in
          your account, by placing a prominent notice on our Asritsolutions LLC,
          and/or by updating any privacy information. Your continued use of the
          application and/or services available after such modifications will
          constitute your: (a) acknowledgment of the modified Policy; and (b)
          agreement to abide and be bound by that Policy.
        </div>
        <h4 style={{marginTop: 25}}>Contact Information</h4>
        <div>
          The Company welcomes your questions or comments regarding this Policy.
          If you believe that the Company has not adhered to this Policy, please
          contact the Company at:
        </div>
         <div>Asritsolutions LLC</div>
        <div>Pleasanton, California 94588</div> 
        <div>Email Address: support@asritsolutions.com</div> 
        <div>Phone Number: +1 (888) 740 - 0937</div> 
        <div>Effective as of August 19, 2024</div> 
      </div>
    );
  }
}

export default Content;
