import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

const items = [
    {
        title: "",
        alt: "TMS",
        icon: "TMS",
        detail:
            "TMS - Run your trucking business successfully with our affordable, easy-to-use solution."
    },
    {
        title: "",
        alt: "Load payment",
        icon: "Loadpayment",
        detail:
            "Load payment - Decide when and how you get paid, factoring and quickpay."
    },
    {
        title: "",
        alt: "Mobile Service",
        icon: "MobileService",
        detail:
            "Mobile services - Access our load board, post your trucks, and find loads right from your mobile device."
    },
    {
        title: "",
        alt: "free",
        icon: "Unlimited",
        detail: "FREE unlimited users and unlimited trucks."
    },
    {
        title: "",
        alt: "Accounting",
        icon: "Oneclick",
        detail: "Create invoices, driver settlements, etc with a single click."
    },
    {
        title: "",
        alt: "Custom Documents",
        icon: "CustomDocuments",
        detail: "Create driver lease agreements, owner operator contracts."
    },
    {
        title: "",
        alt: "Driver Onboarding",
        icon: "Onboarding",
        detail: "On line driver onboarding application."
    },
    {
        title: "",
        alt: "",
        icon: "LoadTracking",
        detail: "Easy load confirmations, BOLs, rate agreements, etc."
    },
    {
        title: "",
        alt: "Integration",
        icon: "Integration",
        detail: "Integrate seamlessly with Quickbooks."
    },
    {
        title: "",
        alt: "Eld Integration",
        icon: "EldIntegration",
        detail: "Integrate seamlessly with ELD's for driver HOS data and reatime location tracking."
    },
    {
        title: "",
        alt: "Reports",
        icon: "Reports",
        detail: "Get detailed reporting and statistics."
    },
    {
        title: "",
        alt: "Documents managment",
        icon: "CustomDocuments",
        detail: "Use your own logo on all your documents."
    },
    {
        title: "",
        alt: "Notification",
        icon: "Notification",
        detail: "Free - unlimited load tracking with live updates."
    },
    {
        title: "",
        alt: "Sharelive",
        icon: "Sharelive",
        detail: "Live tracking Page - Share live tracking page to reduce dispatch inquiries."
    },
    {
        title: "",
        alt: "Digital file storage",
        icon: "Storage",
        detail:
            "Digital file storage for cabcards, DL, BOL, POD, load confirmations, agreements and many more."
    },
    {
        title: "",
        alt: "chat",
        icon: "Chat",
        detail: "Two way driver messaging."
    },
    {
        title: "",
        alt: "Check Printing",
        icon: "CheckPrinting",
        detail: "Easy Cheque Printing."
    },
    {
        title: "",
        alt: "Weather Forecasting",
        icon: "Forcasting",
        detail: "In route weather forecasting."
    },
    {
        title: "",
        alt: "Customer Support",
        icon: "24_7",
        detail: "Customer Support 24 * 7."
    }
];


const RowFourContent = props => (

    <Col md="3" style={{ padding: '10px' }}>
        <Card data-aos="flip-right"
            data-aos-delay="600" style={{ height: '100%', alignItems: 'center', padding: "40px 16px" }}>
            <img src={require(`../../images/icons/${props.content.icon}.png`)}
                alt={props.content.alt}
                aria-hidden="true"
            />
            <div >
                <CardBody className="home_row_four_content_body"
                    style={{ textAlign: 'center', padding: 12 }}  >
                    {props.content.detail}
                </CardBody>
            </div>

        </Card>

    </Col>
);


class RowFour extends React.Component {
    render() {
        return (
            <React.Fragment >
                <Row style={{ padding: 16, margin: 0 }}>
                    {items.map((item, i) => (
                        <RowFourContent key={i} index={i + 1} content={item} />
                    ))}
                </Row>
            </React.Fragment>
        );
    }
}

export default RowFour;
