export const ITEMS = [
    {
        title: "ELD Connect",
        icon: "EldIntegration",
        detail: "Let's connect your existing ELD to Osrit to track HOS, location at realtime.",
        alt: "ELD Compliance"
    },
    {
        title: "Load tracking",
        icon: "LoadTracking",
        detail: "One click enabled/disable live load tracking.",
        alt: "Load Tracking Software",
    },
    {
        title: "Load Routing",
        icon: "Onboarding",
        detail: "Automatically calculates empty and loaded mile based on vehicle current location, shipper and consignee.",
        alt: "Dispatch Routing Software"
    },
    {
        title: "IFTA",
        icon: "Onboarding",
        detail:
            "Automatically calculates states miles for you IFTA tax along with dispatch, with the help of vehicle location, shipper and consignee.",
        alt: "IFTA calculation Software"
    },
    {
        title: "Rates",
        icon: "Oneclick",
        detail:
            "Stay competitive with our spot market rate analyzer for a comprehensive look at industry trends.",
        alt: "TMS"
    },
    {
        title: "Settlements",
        icon: "Loadpayment",
        detail: "Create driver settlements (Paychecks) with one click.",
        alt: "Driver Paycheck "
    },
    {
        title: "Receivables",
        icon: "Onboarding",
        detail:
            "Decide when and how you want to get paid–without the factoring service, contracts, or hidden fees.",
        alt: "Accounting Software"
    },
    {
        title: "2 Way Messaging",
        icon: "Chat",
        detail:
            "2 Way Messaging with drivers/carriers via mobile app or fall back to SMS chat.",
        alt: "Trucking Software"
    },
    {
        title: "Notification",
        icon: "Notification",
        detail:
            "Web and push notification for loads, at shipper, check in/out, delivery, etc.",
        alt: "Dispatch Notification"
    },
    {
        title: "Load Confirmation",
        icon: "LoadTracking",
        detail:
            "Use the dependable load tracking software giving you hands-free check calls.",
        alt: "Load Tracking",
    },
    {
        title: "Proof Of Delivery",
        icon: "MobileService",
        detail:
            "Making Proof Of Delivery seamless, driver can do from Osrit mobile app along with document upload.",
        alt: "Delivery Management"
    },
    {
        title: "Load Board",
        icon: "Oneclick",
        detail: "With one click, post available truck after delivery.",
        alt: "Load board"
    }
];
