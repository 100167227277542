import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {me} from '../../actions/appActions';
import RegisterForm from './RegisterForm';
import Header from '../Header';
import './register.scss';

class Register extends React.Component {

    state = {}

    componentDidMount() {
        this.props.me();
    }

    redirectToPage = (url) => {
        this.props.history.push(url);
    }

    render() {
        return (<div id="registerWrapper">
            <Header/>
            <RegisterForm redirectToPage={this.redirectToPage}/>
        </div>);
    }
}

const mapStateToProps = state => ({stripeKey: state.app.stripeKey});

const mapDispatchToProps = dispatch => bindActionCreators({
    me
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Register);
