import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import { Container } from "reactstrap";
import GpsContent from "./GpsContent";
import TextWithTitle from "../common/TextWithTitle";
import { Helmet } from "react-helmet";
import { APP_NAME, isLive } from "../../constants";

class Gps extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <Container
                style={{ padding: 0 }}
                fluid={true}
                id="gpsWrapper"
            >
                <Helmet>
                    <title>GPS - Fleet Tracking - {APP_NAME}</title>
                    {isLive && <meta name="discription" content="Osrit is a fleet management GPS Tracking system designed specifically for trucking businesses. It helps you track your vehicles, employees, drivers, and more." />}
                </Helmet>
                <Banner />
                <Header curr={"gps"} />
                <TextWithTitle
                    title="GPS - Fleet Tracking"
                    text="Osrit is continually improving to meet the needs of today's high demand for trucking and brokerage professionals. Osrit is designed to provide advanced features of Tracking and Remote Immobilization to our customers that keeps the track of various features of vehicles like ignition, speed over limit etc."
                />

                <GpsContent />
            </Container >
        );
    }
}

export default Gps;
