import React from "react";
import { Row, Col } from "reactstrap";
import vtImage from "../../images/visTracks_logo.png";
import ktImage from "../../images/keeptruckin-logo.svg";
import smImage from "../../images/samsara.png";
import seImage from "../../images/smartelds.png";

const itemStyles = {
  fontSize: 20,
  fontWeight: 300,
};

const RenderSe = (props) => (
  <div
    className="price_container"
    style={{
      padding: 20,
      border: 0,
      backgroundColor: "transparent",
      boxShadow: "#c2c6ca 0px 0px 10px",
      textAlign: "center",
    }}
  >
    <div style={{ minHeight: 100, marginBottom: 10 }}>
      <img
        alt="ELD"
        style={{
          minHeight: 50,
          maxWidth: "90%",
        }}
        src={seImage}
      />
    </div>
    <div style={{ minHeight: 200 }}>
      <div style={itemStyles}>Driver Tracking</div>
      <div style={itemStyles}>Generate IFTA Report</div>
      <div style={itemStyles}>Smart Dashcam</div>
      <div style={itemStyles}>Vehicle Diagnostics</div>
      <div style={itemStyles}>Asset Tracking</div>
      <div style={itemStyles}>Vehicle Inspections</div>
    </div>
    <a
      href="https://www.smartelds.com/"
      className="sign_up_btn"
      style={{ maxWidth: "90%" }}
    >
      SIGN UP
    </a>
  </div>
);

const RenderKT = (props) => (
  <div
    className="price_container"
    style={{
      padding: 20,
      border: 0,
      backgroundColor: "transparent",
      boxShadow: "#c2c6ca 0px 0px 10px",
      textAlign: "center",
    }}
  >
    <div style={{ minHeight: 100, marginBottom: 10 }}>
      <img
        alt="ELD"
        style={{
          minHeight: 50,
          maxWidth: "90%",
        }}
        src={ktImage}
      />
    </div>
    <div style={{ minHeight: 200 }}>
      <div style={itemStyles}>Driver Tracking</div>
      <div style={itemStyles}>Generate IFTA Report</div>
      <div style={itemStyles}>Smart Dashcam</div>
      <div style={itemStyles}>Vehicle Diagnostics</div>
      <div style={itemStyles}>Asset Tracking</div>
      <div style={itemStyles}>Vehicle Inspections</div>
    </div>
    <a
      href="https://mbsy.co/VCqm9"
      className="sign_up_btn"
      style={{ maxWidth: "90%" }}
    >
      SIGN UP
    </a>
  </div>
);

const RenderSM = (props) => (
  <div
    className="price_container"
    style={{
      padding: 20,
      border: 0,
      backgroundColor: "transparent",
      boxShadow: "#c2c6ca 0px 0px 10px",
      textAlign: "center",
    }}
  >
    <div style={{ minHeight: 100, marginBottom: 10 }}>
      <img
        alt="ELD"
        style={{
          maxHeight: 100,
          minHeight: 100,
          maxWidth: "90%",
        }}
        src={smImage}
      />
    </div>

    <div style={{ minHeight: 200 }}>
      <div style={itemStyles}>Driver Tracking</div>
      <div style={itemStyles}>Generate IFTA Report </div>
      <div style={itemStyles}>Smart Dashcam</div>
      <div style={itemStyles}>Vehicle Diagnostics</div>
      <div style={itemStyles}>Asset Tracking</div>
      <div style={itemStyles}>Vehicle Inspections</div>
    </div>
    <a
      href="https://www.samsara.com/pricing"
      className="sign_up_btn"
      style={{ maxWidth: "90%" }}
    >
      SIGN UP
    </a>
  </div>
);

class PricingContent extends React.Component {
  onSignUp = () => {};
  render() {
    return (
      <Row className="price_container_wrapper">
        <Col md={4} />
        <Col
          data-aos="fade-right-up"
          data-aos-delay={200}
          style={{ padding: 25 }}
        >
          <RenderSe />
        </Col>
        <Col md={4} />
      </Row>
    );
  }
}

export default PricingContent;
